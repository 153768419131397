import { is } from "date-fns/locale";
import { MP_KEY } from "./constants";
import { updateInstallments } from "./installments";
import { customAlert } from "./ui/alert";
import {
  alertPaymentStatus,
  handleCardErrors,
} from "./utlis/alertPaymentStatus";
import { processPayment } from "./utlis/processPayment";
import { startLoading, stopLoading } from "./utlis/toggleSubmitLoading";

if (window.newcard) {
  const mercadopago = new MercadoPago(MP_KEY, { locale: "es-UY" });

  function loadCardForm() {
    const isDebitDOM = $("#isDebit");
    const cardForm = mercadopago.cardForm({
      amount: $("#amount_uyu").val().replace(",", "."),
      autoMount: true,
      form: {
        id: "paymentForm",
        cardholderName: {
          id: "cardholderName",
          placeholder: "Nombre",
        },
        cardholderEmail: {
          id: "cardholderEmail",
          placeholder: "E-mail",
        },
        cardNumber: {
          id: "cardNumber",
          placeholder: "Número",
        },
        cardExpirationMonth: {
          id: "cardExpirationMonth",
          placeholder: "MM",
        },
        cardExpirationYear: {
          id: "cardExpirationYear",
          placeholder: "YY",
        },
        securityCode: {
          id: "securityCode",
          placeholder: "Código de seguridad",
        },
        installments: {
          id: "installments",
          placeholder: "Cantidad de pagos",
        },
        identificationType: {
          id: "identificationType",
        },
        identificationNumber: {
          id: "identificationNumber",
          placeholder: "Número de documento",
        },
        issuer: {
          id: "issuer",
          placeholder: "Emisor",
        },
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error)
            return console.warn("Form Mounted handling error: ", error);
          console.log("Form mounted");
        },
        onFormUnmounted: (error) => {
          if (error)
            return console.warn("Form Mounted handling error: ", error);
        },
        onSubmit: async (event) => {
          event.preventDefault();

          startLoading();
          $(".form-group-w-error").removeClass("form-group-w-error");

          cardForm.update("token", "");

          const {
            paymentMethodId,
            paymentTypeId,
            issuerId,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData();

          const formData = new FormData();
          formData.append("paymentMethodId", paymentMethodId);
          formData.append("issuerId", issuerId);
          formData.append("installments", installments);
          formData.append("token", token);
          formData.append("docType", $("#identificationType").val());
          formData.append("docNumber", identificationNumber);
          console.log("token", typeof token, `<${token}>`, token.length);
          if (typeof token !== "string" || token === "") {
            console.log("Attempt to buy without card token");
            customAlert("La tarjeta no ha cargado correctamente");
            return false;
          }

          if ($("[name=saveCard]").prop("checked")) {
            formData.append("saveCard", "on");
          }

          const eventid = $("[name=eventid]").val();
          formData.append("eventid", eventid);
          formData.append("paymentMethod", "1");
          formData.append("name", $("[name=firstName]").val());
          formData.append("firstName", $("[name=firstName]").val());
          const valemail = $("[name=email]").val();
          const valphone = $("[name=phone]").val();
          if (typeof valemail !== "string" || valemail === "") {
            const inputEmailGroup = $("[name=email]").closest(".form-group");
            inputEmailGroup.addClass("form-group-w-error");
            inputEmailGroup[0].scrollIntoView({ behavior: "smooth" });
            customAlert(
              "No haz proporcionado una dirección de correo válida. Por favor, ve al paso anterior y completa el campo de correo electrónico."
            );
            stopLoading();
            return false;
          }
          if (typeof valphone !== "string" || valphone === "") {
            const inputPhoneGroup = $("[name=phone]").closest(".form-group");
            inputPhoneGroup.addClass("form-group-w-error");
            inputPhoneGroup[0].scrollIntoView({ behavior: "smooth" });
            customAlert(
              "No haz proporcionado un número de teléfono válido. Por favor, ve al paso anterior y completa el campo de teléfono."
            );
            stopLoading();
            return false;
          }
          if (
            typeof window.isWebview !== "undefined" &&
            window.isWebview === true
          ) {
            formData.append("webview", true);
          }
          formData.append("email", $("[name=email]").val());
          formData.append("phone", $("[name=phone]").val());
          formData.append("cartjson", $("[name=cartjson]").val());
          const billingInfo = $("[name=billing]").val();
          if (typeof billingInfo === "string" && billingInfo !== "") {
            formData.append("billing", billingInfo);
          }

          const isDebit =
            isDebitDOM.length > 0 ? parseInt(isDebitDOM.val()) : 1;
          if (isDebit < 0) {
            console.warn("isDebit < 1", isDebit);
            customAlert("No se pudo obtener el tipo de tarjeta");
            stopLoading();
            return false;
          }
          console.log(
            "updateService onSubmit",
            eventid,
            isDebit ? "isDebit" : "isCredit",
            installments,
            isDebit ? 0 : installments
          );

          // cardForm.createCardToken();

          console.trace("INIT process payment");
          console.log("data:\n{");
          formData.forEach((val, key) => console.log(`  ${key}: ${val}`));
          console.log("}");

          processPayment(formData);
        },
        onPaymentMethodsReceived: (errors, data) => {
          console.log("onPaymentMethodsReceived", errors, data);
          data.forEach((singleData) => {
            console.log("credit or debit", singleData);
            if (isDebitDOM.length > 0) {
              switch (singleData.payment_type_id) {
                case "credit_card":
                  console.log("set isDebit input", 1);
                  isDebitDOM.val(1);
                  break;
                case "debit_card":
                  console.log("set isDebit input", 0);
                  isDebitDOM.val(0);
                  $("#installments").val(1);
                  break;
                default:
                  console.log("set isDebit input", -1);
                  isDebitDOM.val(-1);
              }
            }
            switch (singleData.payment_type_id) {
              case "credit_card":
                console.log("It's credit card!");
                break;
              case "debit_card":
                console.log("It's debit card!");
                break;
              default:
                console.warn(
                  "unidentified payment method",
                  singleData.payment_type_id
                );
            }
          });
          updateInstallments();
        },
        onInstallmentsReceived: (errors, data) => {
          updateInstallments();
          console.log("onInstallmentsReceived", errors, data);
          if (typeof data !== "undefined") {
            console.log("installments data", data);

            // if (Array.isArray(data) && data.length > 1) {
            //   $("#installments-group").show();
            // } else {
            //   $("#installments-group").hide();
            // }
          }
          if (typeof errors !== "undefined") {
            console.warn("on installments Error", errors);
            handleCardErrors(errors);
          }
        },
        onCardTokenReceived: (errors) => {
          console.log("onCardTokenReceived", errors);
          // handleCardErrors(errors);
          if (typeof errors === "undefined") {
            $(".form-group-w-error").removeClass("form-group-w-error");
          }
        },
        onError: (error, event) => {
          console.warn("onError", error, event);
          handleCardErrors(error);
        },
        onFetching: (resource) => {
          console.log("Fetching resource: ", resource);
          console.log("should startLoading()?");
          return () => {
            console.log("should stopLoading()?");
          };
        },
      },
    });
    window.cardForm = cardForm;
  }
  window.loadCardForm = loadCardForm;

  loadCardForm();
}
