import { updateTicketPrice } from "./tickets";
import { formatDate } from "./utlis/dates";

const addProductModal = $("#add-product-modal");
const addProductBtn = addProductModal.find(".btn-primary");
const cartModal = $("#modal-product-cart");

$(document).on("click", ".product", (event) => {
  const btn = $(event.currentTarget);
  const data = btn.data("product");
  console.log("product data", data);
  if (typeof data !== "undefined") {
    openAddProductModal(data);
  }
});

function openAddProductModal(data) {
  addProductBtn.data("product", data);
  const imagesContainer = addProductModal.find(".product-images");
  try {
    imagesContainer.slick("unslick");
    console.log("unslick imagesContainer");
  } catch (error) {
    console.log("unslick error", error);
  }
  imagesContainer.html("");
  const domImages = data.images.map((image) => {
    const img = $("<img>");
    img.addClass("add-product-image");
    img.attr("src", `${image.file}`);
    return img;
  });
  imagesContainer.append(domImages);
  imagesContainer.slick();
  addProductModal.find(".product-name").text(data.name);
  addProductModal.find(".product-description").text(data.description);

  const optionsSelect = addProductModal.find("#add-product-options");
  const optionsRow = optionsSelect.closest(".row");
  optionsSelect.html("");
  if (data.options.length > 0) {
    optionsRow.show();
    data.options.forEach((option) => {
      const domOption = $("<option>");
      domOption.attr("value", option.iopid);
      domOption.text(option.name);
      domOption.data("option", option);
      optionsSelect.append(domOption);
    });
  } else {
    optionsRow.hide();
  }
  optionsSelect.val(optionsSelect.find("option").val());
  addProductBtn.data("option", optionsSelect.find("option").val());
  const currency =
    data?.currency && data.currency !== "UYU" ? data.currency : "$";
  console.log("currency", currency);
  addProductModal.find(".product-price").text(`${currency} ${data.price}`);
  addProductModal
    .find(".btn-primary .text")
    .html(`Agregar 1 ${data.name} <strong>${currency} ${data.price}</strong>`);
  const qtyInput = addProductModal.find(".product-qty");
  qtyInput.val(1);
  addProductBtn.data("qty", 1);

  optionsSelect.off("change");
  optionsSelect.on("change", (event) => {
    const selectedOption = $(event.currentTarget).find("option:selected");
    const newOptionVal = selectedOption.val();
    console.log(newOptionVal);
    if (typeof newOptionVal === "undefined" || newOptionVal === "") {
      addProductBtn.data("option", "");
      const qty = parseInt(qtyInput.val());
      const total = qty * parseFloat(data.price);
      const buttonText = `Agregar... (elija una opción)`;
      addProductModal.find(".btn-primary .text").html(buttonText);
      addProductModal.find(".product-price").text("");
      addProductModal.find(".btn-primary").prop("disabled", true);
    } else {
      addProductBtn.data("option", newOptionVal);
      const optionData = selectedOption.data("option");
      console.log("optionData", optionData);
      const optionPrice = optionData.price;
      const qty = parseInt(qtyInput.val());
      const total = qty * parseFloat(optionPrice);
      const buttonText = `Agregar ${qty} ${data.name} <strong>${currency} ${total}</strong>`;
      addProductModal.find(".btn-primary .text").html(buttonText);
      addProductModal.find(".product-price").text(`${currency} ${optionPrice}`);
      addProductModal.find(".btn-primary").prop("disabled", false);
    }
  });

  qtyInput.off("change");
  qtyInput.on("change", (event) => {
    const input = $(event.currentTarget);
    const newValue = input.val();
    addProductBtn.data("qty", parseInt(newValue));

    let price = parseFloat(data.price);

    const optionsSelect = addProductModal.find("#add-product-options");
    if (optionsSelect.is(":visible")) {
      console.log("optionsSelect", optionsSelect.val());
      const selectedOption = optionsSelect.find("option:selected");
      const optionData = selectedOption.data("option");
      console.log("optionData", optionData);
      if (typeof optionData !== "undefined" && optionData !== "") {
        price = parseFloat(optionData.price);
      }
    }

    const total = parseInt(newValue) * price;
    const buttonText = `Agregar ${newValue} ${data.name} <strong>${currency} ${total}</strong>`;
    addProductBtn.find(".text").html(buttonText);
  });

  addProductModal.modal("show");
}

$(".cart-form .suggestion-view-more").on("click", (event) => {
  const btn = $(event.currentTarget);
  if (btn.attr("aria-expanded") === "false") {
    btn.collapse("show");
    btn.attr("aria-expanded", "true");
  } else {
    btn.collapse("hide");
    btn.attr("aria-expanded", "false");
  }
});

$(document).on("click", "#add-product-btn", function (event) {
  const btn = $(event.currentTarget);
  const product = btn.data("product");
  const qty = btn.data("qty");
  const optionVal = btn.data("option");
  const option = product.options.find(
    (singleOption) => singleOption.iopid === optionVal
  );
  console.log("product", product, "option", option, "qty x" + qty);

  const hasOptions = product.options.length > 0;
  if (hasOptions && !option) {
    alert("Debes elegir una opción");
    $("#add-product-options").trigger("focus");
    return;
  }
  addProduct(product.eventid, product.itemid, option ? option.iopid : "", qty);
});

$(document).on(
  "click",
  ".product-cart-item-qty-container .btn-minus",
  function (event) {
    const btn = $(event.currentTarget);
    const domItem = btn.closest(".product-cart-item");
    const product = domItem.data("product");
    const ticket = domItem.data("ticket");
    console.log(product, ticket);
    if (typeof product !== "undefined") {
      addItemQuantity(product, -1, domItem);
    } else if (typeof ticket !== "undefined") {
      addItemQuantity(ticket, -1, domItem);
    }
  }
);

$(document).on(
  "click",
  ".product-cart-item-qty-container .btn-plus",
  function (event) {
    const btn = $(event.currentTarget);
    const domItem = btn.closest(".product-cart-item");
    const product = domItem.data("product");
    const ticket = domItem.data("ticket");
    console.log(product, ticket);
    if (typeof product !== "undefined") {
      addItemQuantity(product, 1, domItem);
    } else if (typeof ticket !== "undefined") {
      addItemQuantity(ticket, 1, domItem);
    }
  }
);

$(document).on(
  "click",
  ".product-cart-item-qty-container .btn-delete",
  function (event) {
    const btn = $(event.currentTarget);
    const domItem = btn.closest(".product-cart-item");
    const product = domItem.data("product");
    const ticket = domItem.data("ticket");
    domItem.css("opacity", 0.2);
    console.log(product, ticket);
    if (typeof product !== "undefined") {
      editProduct(product.eventid, product.itemid, product.iopid, 0);
    } else if (typeof ticket !== "undefined") {
      const optionHash = `0|${ticket.etid}`;
      const option = $(`select.quantity option[value="${optionHash}"]`);
      if (option.length > 0) {
        console.log("option select", option);
        option.prop("selected", true);
      } else {
        console.log("option not available");
      }
      const invite = domItem.data("invite");
      editTicket(ticket.eventid, ticket.etid, 0, invite, null);
    }
  }
);

$(document).on("change", ".ticket .quantity", function (event) {
  const select = $(event.currentTarget);
  const ticketDom = select.closest(".ticket");
  const ticketData = ticketDom.data("ticket");
  const option = select.find("option:selected");
  const quantVal = option.val().split("|");
  const quant = parseInt(quantVal[0]);
  const price = quant * parseFloat(ticketData.price);
  const invite = $('[name="invite"]').val();
  const rrpp = $('[name="rrpp"]').val();
  if (checkIfDiffernetTicketsHaveDifferentPromos(ticketData.etid)) {
    alert(
      "Solo puedes seleccionar un tipo de ticket con promoción\nVacía el carrito para seleccionar un tipo de promoción por compra."
    );
    select.val("0|" + ticketData.etid);
    updateTicketPrice();
  } else {
    editTicket(ticketData.eventid, ticketData.etid, quant, invite, rrpp);
  }
});

function checkIfDiffernetTicketsHaveDifferentPromos(currentEtid) {
  let currentPromo = null;
  let allPromos = [];
  $(".ticket").each(function (index, element) {
    const ticketDom = $(element);
    const ticketData = ticketDom.data("ticket");
    const option = ticketDom.find("option:selected");
    const quantVal = option.val().split("|");
    const quant = parseInt(quantVal[0]);
    if (quant > 0) {
      var singlePromo = parseInt(ticketData.promo);
      if (ticketData.etid === currentEtid) {
        currentPromo = singlePromo;
      }
      if (allPromos.indexOf(singlePromo) === -1) {
        allPromos.push(singlePromo);
      }
    }
  });
  return allPromos.length > 1;
}

let modalHasChanged = false;

$(document).on("show.bs.modal", "#modal-product-cart", function () {
  modalHasChanged = false;
});

$(document).on("hidden.bs.modal", "#modal-product-cart", function () {
  if (
    typeof window.buycheckout === "boolean" &&
    window.buycheckout &&
    modalHasChanged
  ) {
    window.location.reload();
  }
  modalHasChanged = false;
});

$(document).on("change", "#quantity", function (event) {
  var ele = $(event.currentTarget);
  console.log("change quantity", ele.val());
});

function addItemQuantity(item, diff, domItem) {
  const btnPlus = domItem.find(".btn-plus");
  const btnMinus = domItem.find(".btn-minus");
  const qtyInput = domItem.find(".product-cart-item-qty");

  let minquantity = 1;
  if (typeof item.optiondata !== "undefined") {
    if (Array.isArray(item.optiondata.options)) {
      if (typeof item.etid !== "undefined") {
        const option = item.optiondata.options.find(
          (singleOption) => singleOption.etid === item.etid
        );
        if (typeof option !== "undefined") {
          minquantity = parseInt(option.minquantity);
        }
      }
    }
  }

  let newQty = parseInt(item.qty) + diff * minquantity;

  if (newQty < minquantity + 1) {
    btnMinus.prop("disabled", true);
    if (newQty < minquantity) {
      return;
    }
  } else {
    btnMinus.prop("disabled", false);
  }

  let is_available = true;
  $("#tickets-form .row.ticket").each((i, ticketRow) => {
    const ticketData = $(ticketRow).data("ticket");
    console.log(item.etid, ticketData.etid);
    if (ticketData.eventid === item.eventid && ticketData.etid === item.etid) {
      const optionHash = `${newQty}|${item.etid}`;
      const option = $(ticketRow).find(
        `select.quantity option[value="${optionHash}"]`
      );
      if (option.length > 0 && !option.prop("disabled")) {
        console.log("option select", option);
        option.prop("selected", true);
      } else {
        console.log("option not available");
        is_available = false;
      }
    }
  });

  const invite = $('[name="invite"]').val();
  if (typeof invite === "string" && invite.length > 0) {
    updateTicketPrice();
  }

  if (is_available) {
    qtyInput.text(newQty);
    if (typeof item.itemid !== "undefined") {
      editProduct(item.eventid, item.itemid, item.iopid, newQty);
    } else if (typeof item.etid !== "undefined") {
      const invite = $('[name="invite"]').val();
      const rrpp = $('[name="rrpp"]').val();
      editTicket(item.eventid, item.etid, newQty, invite, rrpp);
    }
  }
}

function addProduct(eventid, itemid, iopid, qty, callback = () => {}) {
  callAPI({
    url: "/actions/addProduct",
    data: {
      eventid: eventid,
      itemid: itemid,
      iopid: iopid,
      qty: qty,
    },
    success: (response) => {
      console.log("response", response);
      addProductModal.modal("hide");
      getCart(() => cartModal.modal("show"));
      callback(response);
    },
    error: (jqXHR, textStatus, asdf) => {
      console.log("addProduct error", jqXHR, textStatus, asdf);
    },
  });
}

function editProduct(eventid, itemid, iopid, qty, callback = () => {}) {
  modalHasChanged = true;
  callAPI({
    url: "/actions/editCartProduct",
    data: {
      eventid: eventid,
      itemid: itemid,
      iopid: iopid,
      qty: qty,
    },
    success: (response) => {
      console.log("response", response);
      getCart();
    },
    error: (jqXHR, textStatus, asdf) => {
      console.log("editProduct error", jqXHR, textStatus, asdf);
    },
  });
}

function editTicket(eventid, etid, qty, invite, rrpp) {
  modalHasChanged = true;
  callAPI({
    url: "/actions/editCartTicket",
    data: {
      eventid: eventid,
      etid: etid,
      qty: qty,
      invite: invite,
      rrpp: rrpp,
    },
    success: (response) => {
      console.log("response", response);
      getCart();
    },
    error: (jqXHR, textStatus, asdf) => {
      console.log("editTicket error", jqXHR, textStatus, asdf);
    },
  });
}

function getCart(callback = () => {}) {
  callAPI({
    url: "/actions/getCart",
    success: (response) => {
      if (typeof response.cart !== "undefined") {
        populateCart(response.cart);
        callback(response.cart);
      }
    },
  });
}

function populateCart(cart) {
  cartModal.data("cart", cart);

  let currency =
    typeof cart.tickets !== "undefined" &&
    cart.tickets !== null &&
    typeof cart.tickets.tickets !== "undefined" &&
    cart.tickets.tickets.length > 0 &&
    typeof cart.tickets.tickets[0].eventdata !== "undefined" &&
    typeof cart.tickets.tickets[0].eventdata !== "UYU"
      ? cart.tickets.tickets[0].eventdata.currency
      : "$";

  cartModal.find(".product-cart-currency").text(currency);
  cartModal.find(".product-cart-total").text(cart.total);
  cartModal.find(".product-cart-total-items").text(cart.total_items);

  $(".cart-overlay-btn").prop(
    "disabled",
    cart.qty === 0 && cart.qty_items === 0
  );
  if (cart.qty > 0 || cart.qty_items > 0) {
    if (cart.qty > 0) {
      $(".cart-bubble").fadeIn("slow");
      $(".cart-overlay-tickets").text(
        `${cart.qty} ticket${cart.qty === 1 ? "" : "s"}`
      );
      $(".cart-overlay-price").text(`${currency} ${cart.total}`);
      $(".cart-overlay-tickets-summary").show();
      $(".cart-overlay").removeClass("collapsed");
      $("#modal-product-cart-tickets-dialog").show();
    } else {
      $(".cart-overlay-tickets-summary").hide();
      $("#modal-product-cart-tickets-dialog").hide();
    }
    if (cart.qty_items > 0) {
      $(".cart-bubble").fadeIn("slow");
      $(".cart-overlay-items").text(
        `${cart.qty_items} item${cart.qty_items === 1 ? "" : "s"}`
      );
      $(".cart-overlay-items-price").text(`${currency} ${cart.total_items}`);
      $(".cart-overlay-items-summary").show();
      $(".cart-overlay").removeClass("collapsed");
      $("#modal-product-cart-items-dialog").show();
    } else {
      $(".cart-overlay-items-summary").hide();
      $("#modal-product-cart-items-dialog").hide();
    }
  } else {
    $(".cart-bubble").fadeOut("slow");
    $(".cart-overlay-tickets-summary").hide();
    $(".cart-overlay-items-summary").hide();
    $(".cart-overlay").addClass("collapsed");
  }
  if (typeof cart.msg === "string" && cart.msg !== "") {
    cartModal.find(".alert").show();
    cartModal.find(".alert").text(cart.msg);
  } else {
    cartModal.find(".alert").hide();
    cartModal.find(".alert").text(cart.msg);
  }
  const ticketsContainer = cartModal.find(
    ".product-cart-modal-tickets-container"
  );
  ticketsContainer.html("");
  console.log("tickets", typeof cart.tickets?.tickets);
  if (cart?.tickets?.tickets) {
    if (!Array.isArray(cart?.tickets?.tickets)) {
      cart.tickets.tickets = Object.values(cart.tickets.tickets);
    }
    cart.tickets.tickets.forEach((ticket) => {
      const domItem = $("#product-cart-item-template").clone();
      domItem.removeAttr("id");
      domItem.data("ticket", ticket);
      if (typeof ticket.invite !== "undefined") {
        domItem.addClass("invite-ticket");
        domItem.data("invite", ticket.invite);
      }

      if (
        typeof ticket.eventdata !== "undefined" &&
        typeof ticket.eventdata.image !== "undefined"
      ) {
        domItem
          .find(".product-cart-item-image")
          .attr("src", ticket.eventdata.image);
      } else {
        domItem.find(".product-cart-item-image").hide();
      }
      domItem.find(".product-cart-item-name").text(ticket.eventname);
      domItem.find(".product-cart-item-option").text(ticket.extra.name);
      domItem
        .find(".product-cart-item-option")
        .attr("title", `${cart.tickets.event} - ${ticket.extra.name}`);

      domItem
        .find(".product-cart-item-description")
        .text(formatDate(ticket.eventdata.start));
      domItem.find(".product-cart-item-qty").text(ticket.qty);
      domItem.find(".btn-minus").prop("disabled", parseInt(ticket.qty) < 2);
      domItem.find(".product-cart-item-currency").text(ticket.extra.currency);
      domItem.find(".product-cart-item-price").text(`$${ticket.total}`);
      domItem.show();
      ticketsContainer.append(domItem);
    });
  }

  const itemsContainer = cartModal.find(".product-cart-modal-items-container");
  itemsContainer.html("");
  if (typeof cart.items !== "undefined") {
    Object.keys(cart.items).forEach((key) => {
      const item = cart.items[key];
      const domItem = $("#product-cart-item-template").clone();
      domItem.removeAttr("id");
      domItem.data("product", item);

      if (item.data.images.length > 0) {
        domItem
          .find(".product-cart-item-image")
          .attr("src", `${item.data.images[0].file}`);
      } else {
        domItem.find(".product-cart-item-image").hide();
      }
      domItem.find(".product-cart-item-name").text(item.data.name);
      const option = item.data.options.find(
        (singleOption) => singleOption.iopid === item.iopid
      );
      if (option) {
        domItem.find(".product-cart-item-option").text(option.name);
        domItem
          .find(".product-cart-item-option")
          .attr("title", `${option.name} - ${option.description}`);
      }
      domItem
        .find(".product-cart-item-description")
        .text(item.data.description);
      domItem.find(".product-cart-item-qty").text(item.qty);
      domItem.find(".btn-minus").prop("disabled", parseInt(item.qty) < 2);
      domItem.find(".product-cart-item-price").text(`$${item.total}`);
      domItem.show();
      itemsContainer.append(domItem);
    });
  }

  $(".cart-bubble").text(cart.qty + cart.qty_items);
  cartModal
    .find("#product-cart-buy-btn")
    .prop("disabled", parseInt(cart.qty) <= 0);
  cartModal
    .find("#product-cart-buy-items-btn")
    .prop("disabled", parseInt(cart.qty_items) <= 0);
}

$(document).on(
  "submit",
  `#modal-product-cart [action="/comprar"]`,
  function (e) {
    if (!window.isLogged) {
      e.preventDefault();
      //Send the user to the login page
      $("#buy-not-logged-modal").modal("show");
      $("#modal-product-cart").modal("hide");
    }
  }
);

$(document).on("click", ".btn-validate-promo", function (e) {
  var btn = $(e.currentTarget);
  var input = btn.siblings('input[name="promocode[]"]');
  validatePromoCode(input);
});

$(document).on("click", ".btn-validate-wallet-promo", function (e) {
  var btn = $(e.currentTarget);
  var input = btn.siblings('input[name="walletpromocode"]');
  validateWalletPromoCode(input);
});

$(document).on("click", ".btn-clear-promo", function (e) {
  var btn = $(e.currentTarget);
  var codeInput = btn.siblings('input[name="promocode[]"]');
  var promoInput = btn.siblings('input[name="promo[]"]');
  var promo = promoInput.val();
  var code = codeInput.val();
  if (promo !== "") {
    removePromoCode(promo, code);
  }
});

$(document).on("input", `[name="promocode[]"]`, function (e) {
  var input = $(e.currentTarget);
  var code = input.val();
  var validateBtn = input.siblings(".btn-validate-promo");
  if (code !== "") {
    validateBtn.prop("disabled", false);
  } else {
    validateBtn.prop("disabled", true);
  }
});

$(document).on("change", `[name="promocode[]"]`, function (e) {
  var input = $(e.currentTarget);
  validatePromoCode(input);
});

$(document).on("click", `#redeem-balance-code-btn`, function (e) {
  var input = $(e.currentTarget)
    .closest("#redeem-balance-code")
    .find('input[name="walletpromocode"]');
  validateWalletPromoCode(input);
});

function resetRedeemBalancePromo() {
  var container = $("#redeem-balance-code");
  var successModal = $("#redeem-success-modal");
  var input = container.find('input[name="walletpromocode"]');

  successModal.find(".success-message").hide();
  successModal.find(".success-message .amount").text("$0.00");
  successModal.find("[href='/swap']").hide();

  container.find(".error-message").hide();
  container.find(".promo-code-container input").val("");
  container.find(".promo-code-container img.loader").hide();
  container.find(".btn-validate-wallet-promo").prop("disabled", false);

  input.prop("readonly", false);
  input.val("");
}

$(document).on("hidden.bs.modal", "#redeem-success-modal", function (e) {
  resetRedeemBalancePromo();
});

function validateWalletPromoCode(input) {
  // check if the end of the url is /swap
  var isSwap = window.location.pathname.endsWith("/swap");
  var code = input.val();
  var container = $("#redeem-balance-code");
  var eventid = container.find("[name=eventid]").val();
  var loader = $(".promo-code-container img.loader");
  loader.show();
  input.prop("readonly", true);
  container.find(".btn-validate-wallet-promo").prop("disabled", true);
  container.find(".promo-code-error").hide();
  callAPI({
    url: "/actions/checkItemPromoCode",
    method: "post",
    dataType: "json",
    data: { code: code, eventid: eventid },
    success: function (response) {
      setTimeout(function () {
        loader.hide();
      }, 500);
      if (
        typeof response.payment !== "undefined" &&
        typeof response.payment.pstatus !== "undefined" &&
        response.payment.pstatus === "success"
      ) {
        console.log("response", response);
        var successModal = $("#redeem-success-modal");
        successModal.modal("show");
        successModal
          .find(".success-message p strong")
          .text("$" + response.invoice.amount);
        successModal.find(".success-message").show();
        if (typeof response.action === "string") {
          if (response.action === "/swap" && !isSwap) {
            successModal.find("[href='/swap']").show();
          } else {
            successModal.find(".modal-body [data-dismiss='modal']").show();
            setTimeout(function () {
              window.location.href = response.action;
            }, 3000);
          }
        }
        successModal.on("hidden.bs.modal", function (e) {
          window.location.reload();
        });
      } else {
        input.prop("readonly", false);
        container.find(".btn-validate-wallet-promo").prop("disabled", false);
        if (typeof response.msg === "string" && response.msg !== "") {
          container.find(".promo-code-error").text(response.msg);
        } else {
          container
            .find(".promo-code-error")
            .text("No pudimos validar el código");
        }
        container.find(".promo-code-error").show();
      }
    },
    error: function () {
      alert("Hubo un error al intentar agregar el código de promoción");
      input.prop("readonly", false);
      container.find(".btn-validate-wallet-promo").prop("disabled", false);
      setTimeout(function () {
        loader.hide();
      }, 500);
    },
  });
}

function validatePromoCode(input) {
  var code = input.val();
  var container = input.closest(".cart-promo-code");
  var eventid = container.data("eventid");
  var etids = container.data("etids");
  var promo = container.data("promo");
  var validateBtn = container.find(".btn-validate-promo");
  validateBtn.prop("disabled", true);

  var discountRow = $(".cart-table-item-discount");
  discountRow.hide();
  discountRow.find(".cart-table-item-qty").text("");
  discountRow.find(".cart-table-item-price").text("");

  var promoCodeError = $(".promo-code-error");
  promoCodeError.hide();

  $(".promo-item.promo-item-" + promo).removeClass("promo-item-active");
  if (code !== "" && etids.length > 0 && promo !== "") {
    var loader = $(".promo-code-container img.loader");
    loader.show();
    callAPI({
      url: "/actions/addPromoCode",
      method: "post",
      dataType: "json",
      data: { code: code, eventid: eventid, etids: etids, promo: promo },
      success: function (response) {
        setTimeout(function () {
          loader.hide();
        }, 500);
        if (
          typeof response.status === "string" &&
          response.status === "success"
        ) {
          if (response.action === "reload") {
            window.location.reload();
            return;
          }
          $(".promo-item.promo-item-" + promo).addClass("promo-item-active");
          validateBtn.prop("disabled", true);
          if (
            typeof response.discount !== "undefined" &&
            response.discount.price !== 0
          ) {
            discountRow.show();
            discountRow
              .find(".cart-table-item-qty")
              .text(response.discount.qty);
            discountRow
              .find(".cart-table-item-price")
              .text(response.discount.price);
          }
        } else {
          if (code !== "") {
            validateBtn.prop("disabled", false);
          }
          promoCodeError.show();
        }
      },
      error: function () {
        alert("Hubo un error al intentar agregar el código de promoción");
        setTimeout(function () {
          loader.hide();
        }, 500);
        window.location.reload();
      },
    });
  }
}

$(document).on("click", "#checkout-btn", function (e) {
  var areAllActive = true;
  $(".promo-item").each(function (index, element) {
    var promoItem = $(element);
    var promoType = promoItem.data("promotype");
    console.log("promo item", promoItem, promoType);
    if (promoType == "1" && !promoItem.hasClass("promo-item-active")) {
      areAllActive = false;
    }
  });
  if (!areAllActive) {
    e.preventDefault();
    alert("Sólo puedes comprar tickets si el código de promoción es válido");
    $('[name="promocode[]"]').focus();
    return false;
  }
});

function removePromoCode(promo, code) {
  var discountRow = $(".cart-table-item-discount");
  discountRow.hide();
  discountRow.find(".cart-table-item-qty").text("");
  discountRow.find(".cart-table-item-price").text("");

  var promoCodeError = $(".promo-code-error");
  promoCodeError.hide();

  var loader = $(".promo-code-container img.loader");
  loader.show();
  callAPI({
    url: "actions/removePromoCode",
    method: "post",
    dataType: "json",
    data: { code: code, promo: promo },
    success: function (response) {
      setTimeout(function () {
        loader.hide();
      }, 500);
      if (
        typeof response.status === "string" &&
        response.status === "success"
      ) {
        if (response.action === "reload") {
          window.location.reload();
          return;
        }
      }
      alert("Hubo un error al intentar eliminar el código de promoción");
      window.location.reload();
    },
    error: function () {
      alert("Hubo un error al intentar eliminar el código de promoción");
      setTimeout(function () {
        loader.hide();
      }, 500);
      window.location.reload();
    },
  });
}

function callAPI(params) {
  $.ajax({
    method: "post",
    dataType: "json",
    success: (response) => {
      console.log(params.url, "response", response);
    },
    error: (jqXHR, textStatus, asdf) => {
      console.log(params.url, " error", jqXHR, textStatus, asdf);
    },
    ...params,
  });
}

getCart();

const qrModal = $("#qr-item-modal");
const statusTexts = qrModal.find(".qr-status").data("qstatustexts");

$(document).on("hidden.bs.modal", "#qr-item-modal", function (e) {
  qrModal.data("qr", null);
  qrModal.data("item", null);

  const modalContent = qrModal.find(".modal-content");
  modalContent.removeClass();
  modalContent.addClass("modal-content");

  qrModal.find(".qr-title").text("");
  qrModal.find(".qr-description").text("");
  qrModal.find(".qr-image").attr("src", "");
  qrModal.find(".qr-status").text("");
  qrModal.find(".qr-qrid").text("");
  qrModal.find(".qr-fsdate").text("");
});

$(document).on(
  "click",
  '[data-toggle=modal][data-target="#qr-item-modal"]',
  function (e) {
    const btn = $(e.currentTarget);
    const qrData = btn.data("qr");
    const itemContainer = btn.closest(".order-single-item");
    const itemData = itemContainer.data("item");

    qrModal.data("qr", qrData);
    qrModal.data("item", itemData);

    setModalStatus(qrData.qstatus);
    qrModal.find(".qr-title").text(itemData.name);
    qrModal.find(".qr-description").text(itemData.description);
    const qrImgUrl = `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${qrData.qr}`;
    qrModal.find(".qr-image").attr("src", qrImgUrl);
    const idText = itemData.iinvid + "-" + qrData.itqrid;
    qrModal.find(".qr-qrid").text(idText);
    console.log(typeof qrData.fsdate, qrData?.fsdate);
    if (typeof qrData.fsdate !== "undefined" && parseInt(qrData.fsdate) !== 0) {
      qrModal.find(".qr-fsdate").text(unixToReadableDateHour(qrData.fsdate));
      qrModal.find(".qr-fsdate").show();
    } else {
      qrModal.find(".qr-fsdate").hide();
    }
    checkProductQRStatus();
  }
);

function setModalStatus(status) {
  const modalContent = qrModal.find(".modal-content");
  modalContent.removeClass();
  modalContent.addClass("modal-content");
  modalContent.addClass("qr-status-" + status);
  const statusText = statusTexts[status];
  qrModal.find(".qr-status").text(statusText);
}

function unixToReadableDateHour(unix) {
  const date = new Date(unix * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  let readableDate = ``;
  const now = new Date();
  let differentYear = false;
  let differentDate = false;
  if (now.getFullYear() !== year) {
    differentYear = true;
  }
  if (differentYear || now.getMonth() + 1 !== month || now.getDate() !== day) {
    readableDate += `${day.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}/${month.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
    differentDate = true;
  }
  if (now.getFullYear() !== year) {
    readableDate += `/${year}`;
  }
  if (differentDate) {
    readableDate += " ";
  }
  readableDate += `${hour.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${seconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
  return readableDate;
}

function checkProductQRStatus() {
  // check if modal is visible
  if (qrModal.is(":visible")) {
    const qrData = qrModal.data("qr");
    const itemData = qrModal.data("item");
    if (typeof qrData.itqrid !== "undefined") {
      callAPI({
        url: "/actions/iqrstatus",
        method: "post",
        dataType: "json",
        data: { itqrid: qrData.itqrid },
        success: function (response) {
          if (
            typeof response.status === "string" &&
            response.status === "success"
          ) {
            const qstatus = response.data.qstatus;
            const fsdate = response.data.fsdate;
            // if (qstatus !== qrData.qstatus) {
            qrData.qstatus = qstatus;
            qrModal.data("qr", qrData);
            setModalStatus(qstatus);
            const statusText = statusTexts[qstatus];
            const btn = $("#item-qr-bt-" + qrData.itqrid);
            btn.removeClass();
            btn.addClass("btn btn-primary btn-block item-qr-btn");
            btn.addClass("item-qr-btn-status-" + qstatus);
            const btnStatusText = btn.find(".item-qr-btn-status-text");
            btnStatusText.text(statusText);
            if (fsdate != 0) {
              qrModal.find(".qr-fsdate").text(unixToReadableDateHour(fsdate));
              qrModal.find(".qr-fsdate").show();
            } else {
              qrModal.find(".qr-fsdate").hide();
            }
            // }
          }
        },
      });
    }
  }
}

setInterval(checkProductQRStatus, 5000);

window.filterItems = function (status = "all") {
  // hide filtered orders
  const orders = $(".items-single-order");
  if (status === "all") {
    orders.show();
  } else {
    orders.hide();
    orders.each(function (index, order) {
      const statuses = $(order).data("statuses");
      console.log("statuses", statuses, typeof status, status);
      if (statuses.includes(status.toString())) {
        $(order).show();
      }
    });
  }

  // hide filtered items
  const items = $(".order-single-item");
  $(".filter-btn-item-status").removeClass("active");
  if (status === "all") {
    updateURLParameter("s", "all");
    $(".filter-btn-item-status-all").addClass("active");
    items.addClass("show-item");
    return;
  }
  $(".filter-btn-item-status-" + status).addClass("active");
  items.each(function (index, item) {
    const qrData = $(item).data("qr");
    let hasStatus = qrData.qstatus.toString() === status.toString();
    if (hasStatus) {
      $(item).addClass("show-item");
    } else {
      $(item).removeClass("show-item");
    }
  });
  updateURLParameter("s", status);
};

function changeURLParameter(url, param, value) {
  var pattern = new RegExp("\\b(" + param + "=).*?(&|$)");
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, "$1" + value + "$2");
  }
  url = url.replace(/[?#]$/, "");
  return url + (url.indexOf("?") > 0 ? "&" : "?") + param + "=" + value;
}

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    return url;
  } else {
    return url;
  }
}

function updateURLParameter(parm, value) {
  var url = window.location.href;
  var hash = location.hash;
  url = url.replace(hash, "");
  if (value == null || value == undefined || value == "") {
    url = removeURLParameter(url, parm);
  } else {
    url = changeURLParameter(url, parm, value);
  }
  window.history.pushState("", "", url + hash);
}

$(document).on("click", ".btn-load-amount", function (e) {
  // set the target input value with the button value
  var button = $(e.currentTarget);
  const targetInput = $(button.data("target"));
  targetInput.val(button.data("amount"));
  targetInput.trigger("change");
});

$(document).on("change", "input.update-btn-load-amount", function (e) {
  // set the class "active" of the button with the same value of the input
  var input = $(e.currentTarget);
  var buttons = input.closest("form").find(".btn-load-amount");
  buttons.removeClass("active");
  buttons.each(function (index, button) {
    if ($(button).data("amount") == input.val()) {
      $(button).addClass("active");
    }
  });
});

$("input.update-btn-load-amount").trigger("change");

$(document).ready(() => {
  removeEmptyCategories();
});

$(document).on("click", ".btn-category", (e) => {
  const btn = $(e.currentTarget);
  updateProductFilter(btn);
});

$(document).on("click", ".category-banner", (e) => {
  console.log("click category banner");
  const banner = $(e.currentTarget);
  let btn = null;
  $(".btn-category").each((i, singleBtn) => {
    console.log("btn", $(singleBtn).data("category").icid, banner.data("icid"));
    if (
      parseInt($(singleBtn).data("category").icid) ===
      parseInt(banner.data("icid"))
    ) {
      btn = $(singleBtn);
    }
  });
  updateProductFilter(btn);
});

function removeEmptyCategories() {
  const categories = $(".btn-category");
  categories.each((i, category) => {
    const categoryIcid = $(category).data("category").icid;
    if (categoryIcid === "all") return;

    const products = $(".products .product:not(.category-banner)");
    let hasProduct = false;
    products.each((i, product) => {
      const productIcid = $(product).data("icid");
      if (parseInt(productIcid) === parseInt(categoryIcid)) {
        hasProduct = true;
      }
    });
    console.log("category", category, hasProduct);
    if (!hasProduct) {
      $(category).remove();
      $("#category-" + categoryIcid).remove();
    }
  });
}

function updateProductFilter(btn) {
  $(".btn-category").removeClass("active");
  btn.addClass("active");
  const icid = btn.data("category").icid;
  console.log("update icid", icid);
  const products = $(".products .product");
  if (icid === "all") {
    products.show();
    return;
  }
  products.each((i, product) => {
    const productIcid = $(product).data("icid");
    if (parseInt(productIcid) === parseInt(icid)) {
      $(product).show();
    } else {
      $(product).hide();
    }
  });
}
