import { customAlert } from "./ui/alert";
import { isDev } from "./utlis/cookies";

function removeCardDOM(pcid) {
  const input = document.getElementById(`single-card-${pcid}`);
  const label = document.querySelector(`label[for=single-card-${pcid}]`);
  input.remove();
  label.remove();
}

function removeCardIntent(btn) {
  customAlert(
    "¿Estás seguro que querés borrar esta tarjeta?",
    {
      msg: "Eliminar",
      callback: () => {
        const pcid = btn.dataset["pcid"];
        const formData = new FormData();
        formData.append("pcid", pcid);
        fetch("/actions/removeCard", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("removeCard response");
            if (response !== null) {
              if (
                typeof response.status === "string" &&
                response.status === "success"
              ) {
                removeCardDOM(pcid);
                return;
              } else if (typeof response.msg === "string") {
                customAlert(response.msg);
                return;
              }
              customAlert("Hubo un error al borrar la tarjeta", {
                msg: "Recargar",
                callback: () => window.location.reload(),
              });
            }
          });
      },
    },
    {
      msg: "Cancelar",
      callback: () => {
        console.log("remove card cancel");
      },
    }
  );
}

const removeCardsButtons = document.getElementsByClassName("card-remove-btn");
console.log("removeCardsButtons", removeCardsButtons);
for (const buttonKey in removeCardsButtons) {
  if (Object.hasOwnProperty.call(removeCardsButtons, buttonKey)) {
    const removeCardBtn = removeCardsButtons[buttonKey];
    console.log("removeCardBtn", removeCardBtn);
    removeCardBtn.classList.remove("d-none");
    removeCardBtn.addEventListener("click", () =>
      removeCardIntent(removeCardBtn)
    );
  }
}
