import MobileDetect from "mobile-detect";
import { getCookie, setCookie } from "./utlis/cookies";

const md = new MobileDetect(window.navigator.userAgent);

if (md.os() === "AndroidOS") {
  $(".hide-android").hide();
} else if (md.os() === "iOS") {
  $(".hide-ios").hide();
}

const hideAppBannerCookie = getCookie("hapb");

if (hideAppBannerCookie !== null && hideAppBannerCookie !== "hapb=") {
  $(".download-app-overlay").addClass("collapsed");
}

console.log(
  "hideAppBannerCookie (",
  typeof hideAppBannerCookie,
  "): '" + hideAppBannerCookie + "'"
);

$(document).on("click", ".btn-app-overlay-close", function (event) {
  $(".download-app-overlay").toggleClass("collapsed");
  setCookie("hapb", new Date().getTime());
});

$(document).on("click", "img.download-app-mockup", function (event) {
  $(".download-app-overlay").removeClass("collapsed");
});

let lastScrollY = window.scrollY;

$(window).on("scroll", (event) => {

  if (window.scrollY > 200 && lastScrollY < window.scrollY) {
    $(".download-app-overlay").addClass("collapsed");
  }
  lastScrollY = window.scrollY;
});
