$(document).on("click", ".select-btn-group .btn-plus-minus", function (event) {
  var btn = $(event.currentTarget);
  var addValue = btn.data("step");
  var select = btn.siblings("select");
  var selectedOption = null;
  var options = $(select).find("option");
  var etid = options[0].value.split("|")[1];
  var maxOption = 0;
  options.each(function (key, singleOption) {
    var currentOption = parseInt($(singleOption).val().split("|")[0]);
    maxOption = Math.max(maxOption, currentOption);
    if ($(singleOption).prop("selected")) {
      selectedOption = currentOption;
    }
  });
  if (selectedOption === null) {
    selectedOption = 0;
  }
  var newOption = Math.max(Math.min(selectedOption + addValue, maxOption), 0);
  $(select)
    .find("option")
    .each(function (key, singleOption) {
      if (singleOption.value === newOption + "|" + etid) {
        $(singleOption).prop("selected", true);
        $(select).trigger("change");
      }
    });
});

$(document).on("click", ".input-btn-group .btn-plus-minus", function (event) {
  var btn = $(event.currentTarget);
  var addValue = btn.text() === "+" ? 1 : btn.text() === "-" ? -1 : 0;
  var input = btn.siblings("input");
  var value = parseInt(input.val());
  var min = parseInt(input.attr("min")) || Number.MIN_SAFE_INTEGER;
  var max = parseInt(input.attr("max")) || Number.MAX_SAFE_INTEGER;
  var step = parseInt(input.attr("step")) || 1;
  var newValue = Math.min(max, Math.max(min, value + addValue * step));
  $(input).val(newValue);
  if (value !== newValue) {
    $(input).trigger("change");
  }
});

$(document).on("change", ".input-btn-group input", function (event) {
  var input = $(event.currentTarget);
  var value = parseInt(input.val());
  var min = parseInt(input.attr("min")) || Number.MIN_SAFE_INTEGER;
  var max = parseInt(input.attr("max")) || Number.MAX_SAFE_INTEGER;
  var step = parseInt(input.attr("step")) || 1;

  if (min > value - step) {
    input.siblings(".btn-minus").attr("disabled", "disabled");
  } else {
    input.siblings(".btn-minus").removeAttr("disabled");
  }
  if (max < value + step) {
    input.siblings(".btn-plus").attr("disabled", "disabled");
  } else {
    input.siblings(".btn-plus").removeAttr("disabled");
  }
});
