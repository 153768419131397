$(document).on("click", "[data-toggle=expand]", function (event) {
  var btn = $(event.currentTarget);
  var target = $(btn.data("target"));
  if (target.hasClass("expanded") || target.hasClass("expanding")) {
    target.removeClass("expanding");
    target.removeClass("expanded");
    target.addClass("dexpanding");
    btn.attr("aria-expanded", false);
    setTimeout(function () {
      target.removeClass("dexpanding");
    });
  } else {
    target.removeClass("dexpanding");
    target.addClass("expanding");
    btn.attr("aria-expanded", true);
    setTimeout(function () {
      if (target.hasClass("expanding")) {
        target.removeClass("expanding");
        target.addClass("expanded");
      }
    }, 1000);
  }
});
