import { MP_KEY } from "./constants";

if (window.cvvwebview) {
  const mp = new MercadoPago(MP_KEY, {
    locale: "es-UY",
  });

  if (typeof window.ReactNativeWebView !== "undefined") {
    setTimeout(() => {
      window.ReactNativeWebView.postMessage("loaded");
    }, 500);
  }

  async function createToken() {
    try {
      const token = await mp.createCardToken({
        cardId: document.getElementById("cardId").value,
        securityCode: document.getElementById("cvv").value,
      });

      // Use the received token to make a POST request to your backend
      console.log("token received: ", token.id);
      if (typeof window.ReactNativeWebView !== "undefined") {
        window.ReactNativeWebView.postMessage(JSON.stringify({ token: token }));
      }
    } catch (e) {
      console.error("error creating token: ", e);
    }
  }

  const form = document.getElementById("cvvForm");
  form.addEventListener("submit", (event) => {
    event.preventDefault();
    createToken();
    return false;
  });
}
