import { stopLoading } from "./toggleSubmitLoading";
import { customAlert } from "../ui/alert";
import { checkAvailable } from "../tickets";
import { alertPaymentStatus } from "./alertPaymentStatus";
import formSubmit from "./formSubmit";

export function processPayment(formData) {
  const confirmURL = window.balancecheckout ? "/confirmBalance" : "/confirmar";

  fetch("/actions/process_payment", {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      console.log("RESPONSE process_payment", response);
      return response.json();
    })
    .then((data) => {
      console.log("proccess_payment response data", data);
      console.log(JSON.stringify(data));

      stopLoading();

      if (data.status === "success") {
        if (
          typeof window.isWebview !== "undefined" &&
          window.isWebview === true
        ) {
          if (typeof data.is_balance !== "undefined" && data.is_balance) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                status: "success",
                data: data,
              })
            );
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                status: "success",
                data: data.data.tickets.map((tck) => tck.tivid),
              })
            );
          }
        } else {
          console.log("data.is_balance", data.is_balance);
          console.log("data.payment", data.payment);
          console.log("data.payment.pstatus", data?.payment?.pstatus);
          console.log("data.invoice", data?.invoice);
          if (typeof data.is_balance !== "undefined" && data.is_balance) {
            if (typeof data.payment !== "undefined") {
              if (
                data.payment.pstatus === "pending" &&
                typeof data.invoice !== "undefined"
              ) {
                console.log(confirmURL);
                formSubmit(confirmURL, "POST", [
                  { name: "pmid", value: data.params.pmid },
                  { name: "wtxid", value: data.invoice.wtxid },
                  { name: "amount", value: data.invoice.amount },
                  { name: "phone", value: data.params.phone },
                  { name: "email", value: data.params.email },
                  { name: "eventid", value: data.params.eventid },
                ]);
              } else if (
                data.payment.pstatus === "approved" ||
                data.payment.status === "approved"
              ) {
                customAlert(
                  `¡Se ha acreditado el saldo!`,
                  {
                    msg: "Continuar",
                    callback: () => {
                      if (
                        typeof data.redirect !== "undefined" &&
                        data.redirect !== null &&
                        data.redirect !== ""
                      ) {
                        window.location.href = data.redirect;
                      } else {
                        window.location.href =
                          "/swap?eventid=" + data.params.eventid;
                      }
                    },
                  },
                  null,
                  "modal-success",
                  "/sc/images/ticky-comtent-green.png"
                );
                setTimeout(() => {
                  window.location.href = "/swap?eventid=" + data.params.eventid;
                }, 5000);
              } else {
                paymentError(data);
              }
            }
          } else if (
            typeof data.data !== "undefined" &&
            typeof data.data.pmid !== "undefined" &&
            data.data.pmid === "4"
          ) {
            console.log(confirmURL);
            formSubmit(confirmURL, "POST", [
              { name: "pmid", value: data.data.pmid },
              { name: "tivid", value: data.tivid },
              { name: "phone", value: data.data.phone },
              { name: "email", value: data.data.email },
              { name: "eventid", value: data.data.eventid },
              ...data.data.tickets.map((ticket) => ({
                name: "cantidad[]",
                value: `${ticket.qty}|${ticket.etid}`,
              })),
            ]);
          } else if (
            typeof data.data !== "undefined" &&
            typeof data.data.pmid !== "undefined" &&
            data.data.pmid === "6"
          ) {
            console.log(confirmURL);
            formSubmit(confirmURL, "POST", [
              { name: "pmid", value: data.data.pmid },
              { name: "tivid", value: data.tivid },
              { name: "phone", value: data.data.phone },
              { name: "email", value: data.data.email },
              { name: "eventid", value: data.data.eventid },
              ...data.data.tickets.map((ticket) => ({
                name: "cantidad[]",
                value: `${ticket.qty}|${ticket.etid}`,
              })),
            ]);
          } else if (
            typeof data.data !== "undefined" &&
            typeof data.data.pmid !== "undefined" &&
            data.data.pmid === "8"
          ) {
            console.log(confirmURL);
            formSubmit(confirmURL, "POST", [
              { name: "pmid", value: data.data.pmid },
              { name: "tivid", value: data.tivid },
              { name: "phone", value: data.data.phone },
              { name: "email", value: data.data.email },
              { name: "eventid", value: data.data.eventid },
              { name: "ci", value: data.data.docNumberPagopar },
              { name: "docType", value: data.data.docTypePagopar },
              ...data.data.tickets.map((ticket) => ({
                name: "cantidad[]",
                value: `${ticket.qty}|${ticket.etid}`,
              })),
            ]);
          } else if (
            typeof data.data !== "undefined" &&
            typeof data.data.pmid !== "undefined" &&
            data.data.pmid === "11" &&
            typeof data.redirect === "string" &&
            data.redirect !== ""
          ) {
            console.log("redirect", data.redirect);
            window.location.href = data.redirect;
          } else {
            location.href =
              "/compra?" +
              data.data.tickets.map((tck) => "ids[]=" + tck.tivid).join("&");
          }
        }
        return;
      }
      if (data.hasOwnProperty("msg")) {
        if (data.msg.indexOf("No hay mas tickets disponibles") > -1) {
          customAlert(data.msg, {
            msg: "Verificar otros tickets disponibles",
            callback: checkAvailable,
          });
        } else {
          customAlert(data.msg);
        }
      } else {
        paymentError(data);
      }
    })
    .catch((error) => {
      console.warn("default error message error", error);
      customAlert("Hubo un error al procesar el pago");
      stopLoading();
    });
}

function paymentError(data) {
  if (
    data.hasOwnProperty("payment") &&
    data.payment.hasOwnProperty("status") &&
    (data.payment.status === "fail" || data.payment.status === "rejected") &&
    data.payment.hasOwnProperty("status_detail")
  ) {
    if (
      data.payment.hasOwnProperty("status_msg") &&
      data.payment.status_msg !== ""
    ) {
      customAlert(
        `${data.payment.status_msg}<br><small>${data.payment.status_detail}</small>`
      );
    } else {
      if (
        data.payment.status_detail.indexOf("Invalid security_code_length") > -1
      ) {
        var input = $("#security_code, #securityCode");
        if (input.length > 0) {
          var inputGroup = input.closest(".form-group");
          if (inputGroup.length > 0) {
            inputGroup.addClass("form-group-w-error");
          }
        } else {
          customAlert(
            `Código de seguridad inválido<br><small>${data.payment.status_detail}</small>`
          );
        }
      } else {
        alertPaymentStatus(data.payment.status_detail);
      }
    }
  } else if (data.hasOwnProperty("pstatus")) {
    customAlert(data.pstatus);
  } else {
    console.warn("default error message");
    customAlert("Hubo un error al procesar el pago");
  }
}

window.alertTest = function () {
  customAlert(
    `¡Se ha acreditado el saldo!`,
    {
      msg: "Continuar",
      callback: () => console.log("test"),
    },
    null,
    "modal-success",
    "/sc/images/ticky-comtent-green.png"
  );
};
