console.log($(".home-events-slider"), $(".home-events-slider").length);
if ($(".home-events-slider").length > 0) {
  $(".home-events-slider").slick({
    arrows: false,
    slidesToShow: 5,
    centerPadding: "15px",
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  });
}
