import { processPayment } from "./utlis/processPayment";
import formSubmit from "./utlis/formSubmit";
import { customAlert } from "./ui/alert";

if (window.buycheckout || window.balancecheckout) {
  const submitButton = $("#paymentForm #checkout-btn");

  $("#paymentForm").on("submit", function (event) {
    event.preventDefault();
    console.log("mp onSubmit");
    const paymentMethod = $("[name=paymentMethod]:checked").val();
    const amountDOM = $("#pmid-" + paymentMethod + "-amount");
    console.log("Amount total", amountDOM);
    if (amountDOM.length > 0) {
      const phone = $("[name=phone]").val();
      let pcid = null;

      const billingInfo = getBillingInfo($("#paymentForm"));

      if (paymentMethod === "1") {
        event.preventDefault();

        if (!billingInfo) {
          return false;
        }

        const pcidInput = $("[name=pcid]:checked");
        console.log("pcidInput", pcidInput);
        const valemail = $("[name=email]").val();
        const valphone = $("[name=phone]").val();
        if (typeof valemail !== "string" || valemail === "") {
          const inputEmailGroup = $("[name=email]").closest(".form-group");
          inputEmailGroup.addClass("form-group-w-error");
          inputEmailGroup[0].scrollIntoView({ behavior: "smooth" });
          customAlert("No haz proporcionad una dirección de correo válida");
          return false;
        }
        if (typeof valphone !== "string" || valphone === "") {
          const inputPhoneGroup = $("[name=phone]").closest(".form-group");
          inputPhoneGroup.addClass("form-group-w-error");
          inputPhoneGroup[0].scrollIntoView({ behavior: "smooth" });
          customAlert("No haz proporcionad teléfono válido");
          return false;
        }

        if (pcidInput.length === 0) {
          if (window.balancecheckout) {
            console.log("billingInfo", billingInfo);
            formSubmit("/confirmBalance", "POST", [
              { name: "eventid", value: $("[name=eventid]").val() },
              { name: "amount", value: $("[name=amount]").val() },
              { name: "pmid", value: paymentMethod },
              { name: "phone", value: valphone },
              { name: "email", value: valemail },
              ...billingInfo,
            ]);
          } else {
            formSubmit("/confirmar", "POST", [
              { name: "eventid", value: $("[name=eventid]").val() },
              { name: "pmid", value: paymentMethod },
              { name: "phone", value: valphone },
              { name: "email", value: valemail },
            ]);
          }
          return false;
        } else {
          const card = pcidInput.data("card");
          pcid = card.pcid;

          console.log("saved card", card);
          if (window.balancecheckout) {
            console.log("billingInfo", billingInfo);
            formSubmit("/confirmBalance", "POST", [
              { name: "eventid", value: $("[name=eventid]").val() },
              { name: "amount", value: $("[name=amount]").val() },
              { name: "pmid", value: paymentMethod },
              { name: "pcid", value: pcid },
              { name: "phone", value: valphone },
              { name: "email", value: valemail },
              { name: "first_six_digits", value: card.first_six_digits },
              ...billingInfo,
            ]);
          } else {
            formSubmit("/confirmar", "POST", [
              { name: "eventid", value: $("[name=eventid]").val() },
              { name: "pmid", value: paymentMethod },
              { name: "pcid", value: pcid },
              { name: "phone", value: valphone },
              { name: "email", value: valemail },
              { name: "first_six_digits", value: card.first_six_digits },
            ]);
          }
          return false;
        }
      } else if (paymentMethod === "4") {
        console.log("bank transfer");
      }
      pay(event);
      return false;
    } else {
      alert(
        "No se ha podido obtener el monto total de la compra, por favor recargue la página e intente nuevamente."
      );
    }
  });

  async function pay(event) {
    console.log("--------- INIT PAY ---------");
    event.preventDefault();
    console.trace("onSubmit");
    const paymentMethod = $("[name=paymentMethod]:checked").val();

    const amountDOM = $("#pmid-" + paymentMethod + "-amount");
    console.log("Amount total", amountDOM);
    if (amountDOM.length > 0) {
      console.log(
        "onSubmit paymentMethod",
        paymentMethod,
        typeof paymentMethod
      );
      submitButton.addClass("loading");
      submitButton.attr("disabled", "disabled");
      console.log("start loading");

      let pcid = null;
      const formData = new FormData();

      if (paymentMethod === "1") {
        console.log("pass through payment method === 1");
      } else {
        formData.append("docType", $("[name=docType]").val());
        formData.append("docNumber", $("[name=docNumber]").val());
      }
      if (paymentMethod === "2") {
        const paymentNetwork = $("[name=paymentNetwork]:checked").val();
        if (typeof paymentNetwork === "undefined") {
          var radioGroup = $(".payment-network-radio");
          if (radioGroup.length > 0) {
            var inputGroup = radioGroup.closest(".custom-form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          submitButton.removeClass("loading");
          submitButton.removeAttr("disabled");
          console.log("stop loading");
          return false;
        }
        formData.append("paymentMethodId", paymentNetwork);
      }

      formData.append("eventid", $("[name=eventid]").val());
      formData.append("paymentMethod", paymentMethod);
      formData.append("name", $("[name=firstName]").val());
      formData.append("firstName", $("[name=firstName]").val());
      const valemail = $("[name=email]").val();
      const valphone = $("[name=phone]").val();
      if (typeof valemail !== "string" || valemail === "") {
        const inputEmailGroup = $("[name=email]").closest(".form-group");
        inputEmailGroup.addClass("form-group-w-error");
        inputEmailGroup[0].scrollIntoView({ behavior: "smooth" });
        submitButton.removeClass("loading");
        submitButton.removeAttr("disabled");
        console.log("stop loading");
        return false;
      }
      if (typeof valphone !== "string" || valphone === "") {
        const inputPhoneGroup = $("[name=phone]").closest(".form-group");
        inputPhoneGroup.addClass("form-group-w-error");
        inputPhoneGroup[0].scrollIntoView({ behavior: "smooth" });
        submitButton.removeClass("loading");
        submitButton.removeAttr("disabled");
        console.log("stop loading");
        return false;
      }
      formData.append("email", $("[name=email]").val());
      formData.append("phone", $("[name=phone]").val());
      if (window.balancecheckout) {
        formData.append(
          "cartjson",
          JSON.stringify({
            type: "balance",
            amount: $("[name=amount]").val(),
          })
        );
      } else {
        formData.append("cartjson", $("[name=cartjson]").val());
        formData.append("products", $("[name=products]").val());
      }

      console.trace("INIT process payment");
      console.log("data:\n{");
      formData.forEach((val, key) => console.log(`  ${key}: ${val}`));
      console.log("}");

      $(".form-group-w-error").removeClass("form-group-w-error");

      if (paymentMethod === "11") {
        const docTypePagopar = $("[name=docTypePagopar]").val();
        const docNumberPagopar = $("[name=docNumberPagopar]").val();
        formData.append("docType", docTypePagopar);
        formData.append("ci", docNumberPagopar);
      }

      if (
        window.balancecheckout &&
        (paymentMethod === "4" || paymentMethod === "2")
      ) {
        const paymentBillingInfo = getBillingInfoForPayment();
        if (!paymentBillingInfo) {
          customAlert("Por favor, completa los datos de facturación");
          submitButton.removeClass("loading");
          submitButton.removeAttr("disabled");
          console.log("stop loading");
          return false;
        } else {
          formData.append("billing", JSON.stringify(paymentBillingInfo));
        }
      } else {
        const billingInfoJSON = $("[name=billing]").val();
        if (typeof billingInfoJSON === "string" && billingInfoJSON !== "") {
          formData.append("billing", billingInfoJSON);
        }
      }

      processPayment(formData);

      return false;
    } else {
      alert(
        "No se ha podido obtener el monto total de la compra, por favor recargue la página e intente nuevamente."
      );
      return false;
    }
  }

  $("[name=paymentMethod]").on("change", async (event) => {
    const paymentMethod = $(event.currentTarget).val();

    if (paymentMethod === "1") {
      const savedCardInputs = $("[name=pcid]");
      if (savedCardInputs.length > 0) {
        $(savedCardInputs[0]).prop("checked", true);
        const cvvContainer = $(".savedCard-cvv-form-group");
        cvvContainer.removeClass("hidden");
        $("#new-credit-card-container").collapse("hide");
        $("#btn-add-card").show();
      }
    }
  });

  $(document).on("change", "[name=pcid]", (event) => {
    const input = $(event.currentTarget);
    const cvvContainer = $(".savedCard-cvv-form-group");
    cvvContainer.removeClass("hidden");
    if (input.prop("checked")) {
      $("#new-credit-card-container").collapse("hide");
      $("#btn-add-card").show();
    } else {
      $("#btn-add-card").hide();
    }
  });

  $(document).on("click", "#btn-add-card", (event) => {
    event.preventDefault();
    const valemail = $("[name=email]").val();
    const valphone = $("[name=phone]").val();
    if (typeof valemail !== "string" || valemail === "") {
      const inputEmailGroup = $("[name=email]").closest(".form-group");
      inputEmailGroup.addClass("form-group-w-error");
      inputEmailGroup[0].scrollIntoView({ behavior: "smooth" });
      customAlert("No haz proporcionad una dirección de correo válida");
      return false;
    }
    if (typeof valphone !== "string" || valphone === "") {
      const inputPhoneGroup = $("[name=phone]").closest(".form-group");
      inputPhoneGroup.addClass("form-group-w-error");
      inputPhoneGroup[0].scrollIntoView({ behavior: "smooth" });
      customAlert("No haz proporcionad teléfono válido");
      return false;
    }
    const billingInfo = getBillingInfo();
    if (billingInfo === false) {
      return false;
    }
    console.log("billingInfo", billingInfo);
    if (window.balancecheckout) {
      formSubmit("/confirmBalance", "POST", [
        { name: "eventid", value: $("[name=eventid]").val() },
        { name: "amount", value: $("[name=amount]").val() },
        { name: "phone", value: valphone },
        { name: "email", value: valemail },
        ...billingInfo,
      ]);
    } else {
      formSubmit("/confirmar", "POST", [
        { name: "phone", value: valphone },
        { name: "email", value: valemail },
      ]);
    }
    return false;
    // const btn = $(event.currentTarget);
    // $("#new-credit-card-container").collapse("show");
    // $("#new-credit-card-container #cardholderName").focus();
    // $("[name=pcid]:checked").prop("checked", false);
    // $(".savedCard-cvv-form-group").addClass("hidden");
  });
}

function getBillingInfo() {
  const form = $("#paymentForm");
  if (form.find("[name=billingEnabled]:checked").length === 0) {
    return [];
  }
  let billingInfo = [];
  const type = form.find("[name=billingType]:checked").val();
  if (type === "2") {
    billingInfo = [
      {
        name: "billing_name",
        value: form.find("[name=billing-rut-Name]").val(),
      },
      {
        name: "billing_rut",
        value: form.find("[name=billing-rut-Rut]").val(),
      },
      {
        name: "billing_address",
        value: form.find("[name=billing-rut-Address]").val(),
      },
      { name: "billing_cp", value: form.find("[name=billing-rut-CP]").val() },
      {
        name: "billing_phone",
        value: form.find("[name=billing-rut-Phone]").val(),
      },
    ];
  } else if (type === "1") {
    billingInfo = [
      {
        name: "billing_name",
        value: form.find("[name=billing-ci-Name]").val(),
      },
      {
        name: "billing_rut",
        value: form.find("[name=billing-ci-Rut]").val(),
      },
      {
        name: "billing_address",
        value: form.find("[name=billing-ci-Address]").val(),
      },
      { name: "billing_cp", value: form.find("[name=billing-ci-CP]").val() },
      {
        name: "billing_phone",
        value: form.find("[name=billing-ci-Phone]").val(),
      },
    ];
  } else {
    customAlert("Debes elegir el tipo de facturación");
    return false;
  }
  let allValid = true;
  billingInfo.forEach((info) => {
    if (typeof info.value !== "string" || info.value.trim() === "") {
      allValid = false;
    }
  });
  if (!allValid) {
    customAlert(
      "Debes completar todos los campos de facturación\no desmarcar la opción de facturación"
    );
    return false;
  }
  billingInfo.push({ name: "billing_type", value: type });
  return billingInfo;
}

function getBillingInfoForPayment() {
  const form = $("#paymentForm");
  const info = getBillingInfo(form);
  if (!info) {
    return false;
  }
  if (info.length === 0) {
    return true;
  }
  const billingInfo = {};
  info.forEach((item) => {
    const newName = item.name.replace("billing_", "");
    billingInfo[newName] = item.value;
  });
  return billingInfo;
}
