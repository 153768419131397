export function startLoading() {
  const submitButton = $(".btn-loader");
  submitButton.addClass("loading");
  submitButton.attr("disabled", "disabled");
  console.log("start loading");
}

export function stopLoading() {
  const submitButton = $(".btn-loader");
  submitButton.removeClass("loading");
  submitButton.removeAttr("disabled");
  console.log("stop loading");
}
