$(document).on("click", ".event-flyer-btn", function (event) {
  var btn = $(event.currentTarget);
  var flyerURL = btn.data("flyer");
  if (typeof flyerURL === "string" && flyerURL !== "") {
    var modal = $("#flyer-modal");
    modal.modal("show");
    modal.find(".flyer-container");
    var img = $("<img>");
    img.attr("src", flyerURL);
    img.addClass("flyer-image");
    modal.find(".modal-body").html(img);
  }
});
