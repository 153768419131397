import { MP_KEY } from "./constants";
import { updateService } from "./updateService";
import { alertPaymentStatus } from "./utlis/alertPaymentStatus";
import { processPayment } from "./utlis/processPayment";
import { startLoading, stopLoading } from "./utlis/toggleSubmitLoading";

let isReady = false;
let lastInstallment = 1;
let updating = false;

if (window.savedcard) {
  const mp = new MercadoPago(MP_KEY, { locale: "es-UY" });

  const card = window.savedcarddata;

  // Append customer cards to select element
  const selectElement = document.getElementById("card_id");

  const optionElement = document.createElement("option");
  optionElement.setAttribute("value", card.card_id);
  optionElement.textContent = `${card.payment_method} terminada en ${card.last_four_digits}`;
  selectElement.appendChild(optionElement);
  const installmentsDom = $("#installments");

  installmentsDom.on("change", function (e) {
    const val = parseInt($(this).val());
    lastInstallment = val;
  });

  window.getInstallments = function (doUpdateService = true) {
    const amountStr = $("#amount_uyu").val();
    const amount = amountStr.replace(",", ".");
    mp.getInstallments({
      amount: amount,
      bin: $("#first_eight").val(),
      locale: "es-UY",
    })
      .then((response) => {
        console.log("getInstallments response", response);
        const useInstallments =
          $("[name=check-installments]:checked")?.val() !== "no";
        updating = true;
        installmentsDom.html("");
        if (Array.isArray(response)) {
          response.forEach((option) => {
            const isDebitInput = document.getElementById("isDebit");
            if (option.payment_type_id && isDebitInput) {
              let installmentsForService = 1;
              switch (option.payment_type_id) {
                case "debit_card":
                  isDebitInput.value = 0;
                  installmentsForService = 0;
                  break;
                case "credit_card":
                  isDebitInput.value = 1;
                  installmentsForService = lastInstallment;
                  break;
                default:
                  isDebitInput.value = -1;
                  break;
              }
              if (!isReady) {
                updateService($("#eventid").val(), "1", installmentsForService);
                isReady = true;
              }
            }
            console.log(
              "update instalmments options",
              lastInstallment,
              useInstallments,
              option.payer_costs.map((e) => e.installments)
            );
            option.payer_costs.forEach((installmentOption, index) => {
              const optDom = $("<option>");
              optDom.attr("value", installmentOption.installments);
              optDom.text(installmentOption.recommended_message);
              installmentsDom.append(optDom);
              if (useInstallments) {
                if (installmentOption.installments === 1) {
                  // hide installments = 1
                  optDom.attr("disabled", "disabled");
                  optDom.css("display", "none");
                } else {
                  // show installments > 1
                  if (
                    (lastInstallment > 1 &&
                      installmentOption.installments === lastInstallment) ||
                    (lastInstallment === 1 &&
                      installmentOption.installments === 2)
                  ) {
                    optDom.prop("selected", "selected");
                  }
                  optDom.removeAttr("disabled");
                  optDom.css("display", "block");
                }
              } else {
                if (installmentOption.installments === 1) {
                  // show installments = 1
                  optDom.prop("selected", "selected");
                  optDom.removeAttr("disabled");
                  optDom.css("display", "block");
                } else {
                  // hide installments > 1
                  optDom.attr("disabled", "disabled");
                  optDom.css("display", "none");
                }
              }
            });
          });
          if (installmentsDom.find("option").length > 1) {
            installmentsDom.closest("form-group").show();
          } else {
            installmentsDom.closest("form-group").hide();
          }
        } else {
          const optDom = $("<option>");
          optDom.attr("value", 1);
          optDom.text(`1 cuota de $${amountStr}.`);
          installmentsDom.append(optDom);
        }
        updating = false;
      })
      .catch((error) => {
        console.log("getInstallments error", error);
        const installmentsDom = $("#installments");
        updating = true;
        installmentsDom.html("");
        const optDom = $("<option>");
        optDom.attr("value", 1);
        optDom.text(`1 cuota de $${amountStr}.`);
        installmentsDom.append(optDom);
        installmentsDom.find("option[value=1]").prop("selected", "selected");
        updating = false;
      });
  };

  window.getInstallments();

  async function createToken() {
    try {
      const token = await mp.createCardToken({
        cardId: document.getElementById("card_id").value,
        securityCode: document.getElementById("security_code").value,
      });

      // Use the received token to make a POST request to your backend
      console.log("token received: ", token.id);
      document.getElementById("token").value = token.id;
    } catch (e) {
      console.error("error creating token: ", e);
    }
  }

  document
    .getElementById("paymentForm")
    .addEventListener("submit", async (event) => {
      event.preventDefault();
      startLoading();

      await createToken();

      const formData = new FormData();
      formData.set("token", $("[name=token]").val());
      formData.set("paymentMethodId", card.payment_method);
      formData.set("customerId", card.customer_id);
      formData.set("issuerId", "");
      formData.set("isSavedCard", true);

      formData.append("eventid", $("[name=eventid]").val());
      formData.append("paymentMethod", $("[name=paymentMethod]").val());
      formData.append("name", $("[name=firstName]").val());
      formData.append("firstName", $("[name=firstName]").val());
      formData.append("installments", $("[name=installments]").val());

      formData.append("email", $("[name=email]").val());
      formData.append("phone", $("[name=phone]").val());
      formData.append("cartjson", $("[name=cartjson]").val());

      const billingInfo = $("[name=billing]").val();
      if (typeof billingInfo === "string" && billingInfo !== "") {
        formData.append("billing", billingInfo);
      }

      processPayment(formData);
      return false;
    });
}
