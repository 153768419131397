export function getCookie(name) {
  const dc = document.cookie;
  const prefix = name + "=";
  let begin = dc.indexOf("; " + prefix);
  let end = -1;
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function cookieExists(name) {
  return getCookie(name) != null;
}

export const isDev = cookieExists("dev");

/**
 * Sets a cookie with the passed values
 * @param {string} name the name of the cookie
 * @param {string} value the value of the cookie
 * @param {Date|undefined} expire the expiration date of the cookie
 */
export function setCookie(name, value, expire) {
  var cookieStr = name + "=" + encodeURI(value);
  if (typeof expire !== "undefined") {
    cookieStr += "; " + expire.toUTCString();
  }
  document.cookie = cookieStr;
}
