$(document).on("submit", "#help form", (event) => {
  event.preventDefault();
  grecaptcha.ready(function () {
    grecaptcha
      .execute(window.RECAPTCHA_PUBLIC, { action: "submit" })
      .then(function (token) {
        const form = $(event.currentTarget);
        document.getElementById("recaptchatoken").value = token;
        const submitBtn = form.find(".btn-primary");
        submitBtn.addClass("loading");
        submitBtn.attr("disabled", "disabled");
        const msgBox = form.find(".alert");
        msgBox.hide();
        msgBox.html("");
        msgBox.removeClass("alert-success");
        msgBox.removeClass("alert-danger");
        fetch("/actions/help", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: form.serialize(),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("get help response", response);
            submitBtn.removeClass("loading");
            submitBtn.removeAttr("disabled");
            if (
              typeof response.status === "string" &&
              response.status === "success"
            ) {
              msgBox.text(
                "Recibimos tu consulta. En breve nos pondremos en comunicación contigo."
              );
              msgBox.addClass("alert-success");
            } else if (typeof response.msg === "string") {
              let msg = response.msg;
              msg = msg.replace(
                "Unknown method",
                "Hubo un error, inténtalo más tarde"
              );
              msgBox.text(msg);
              msgBox.addClass("alert-danger");
            }
            msgBox.show();
          })
          .catch((error) => {
            console.warn("get help error", error);
            submitBtn.removeClass("loading");
            submitBtn.removeAttr("disabled");
            msgBox.text(
              "Hubo un error enviando el mensaje. Intentalo más tarde"
            );
            msgBox.addClass("alert-danger");
            msgBox.show();
          });
      });
  });
  return false;
});

$(document).on("hide.bs.modal", "#help", (event) => {
  const form = $("#help");
  const msgBox = form.find(".alert");
  msgBox.hide();
  msgBox.removeClass("alert-success");
  msgBox.removeClass("alert-danger");
  if (msgBox.hasClass("alert-success")) {
    form.find("textarea").val("");
    form.find("#help-topic").val("otro");
  }
});

$("#help #help-email").closest(".form-group").hide();
