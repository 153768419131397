/**
 * A node in the DOM tree.
 *
 * @external Node
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node Node}
 */

/** Class to handle quicksearch functionality */
export default class QuickSearch {
  /**
   * Create an instance of the class associated with one search input field
   * @param {JQuery.Selector|Node} searchInput the search input selector or element
   */
  constructor(searchInput) {
    this.searchInput = $(searchInput);
    this.inputContainer = $(searchInput).closest(".search-container");
    this.headerContainer = this.inputContainer
      .closest(".sticky-wrapper")
      .find(".menu-header");
    this.searchButton = this.searchInput.siblings(".btn-search");
    this.deleteButton = this.searchInput.siblings(".btn-search-delete");
    this.selector = this.searchInput.data("searchdom");
    /**
     * @type {JQuery}
     */
    this.elements = $(this.selector);
    if (this.elements.length > 0) {
      this.update();

      this.searchInput.off("keyup");
      this.searchInput.on("keyup", (event) => {
        console.log(event);
        if (event.keyCode === 13) {
          this.searchInput.blur();
        }
        this.update();
      });

      this.searchButton.off("click");
      this.searchButton.on("click", (event) => {
        this.inputContainer.removeClass("compact");
        this.searchInput.focus();
        this.update();
      });

      this.deleteButton.off("click");
      this.deleteButton.on("click", (event) => {
        this.searchInput.val("");
        this.update();
      });

      $(window).on("scroll", (event) => {
        console.log("window scroll");
        if (window.scrollY < 200) {
          this.inputContainer.removeClass("compact");
        } else if (this.searchInput.val() === "") {
          this.inputContainer.addClass("compact");
        }
        if (this.headerContainer.length > 0) {
          if (window.scrollY > 200) {
            this.headerContainer.addClass("hide");
          } else {
            this.headerContainer.removeClass("hide");
          }
        }
      });

      const inputContainerWidth = this.inputContainer.width();

      this.inputContainer.css(
        "margin-right",
        (window.innerWidth - inputContainerWidth - 30) * 0.5
      );

      window.onresize = () => {
        this.inputContainer.css(
          "margin-right",
          (window.innerWidth - inputContainerWidth - 30) * 0.5
        );
      };

      if (window.scrollY < 200) {
        this.inputContainer.removeClass("compact");
      } else if (this.searchInput.val() === "") {
        this.inputContainer.addClass("compact");
      }
    }
  }

  update() {
    const searchstr = normalizeString(this.searchInput.val());
    const similars = [];
    const parent = $(this.elements.get(0).parentElement);
    if (searchstr === "") {
      // this.elements.each((i, ele) => parent.append(ele));
      this.elements.show();
      this.searchButton.show();
      this.deleteButton.hide();
      window.scrollTo(0, 0);
    } else {
      this.elements.hide();
      let totalMaxSimilarity = 0;
      this.elements.each((i, element) => {
        const searchvalue = $(element).data("searchvalue");
        let maxSimilarity = 0;
        if (Array.isArray(searchvalue)) {
          searchvalue.forEach((singleValue) => {
            const compareValue = normalizeString(singleValue);
            const similarity = customSimilarity(searchstr, compareValue);
            console.log(searchstr, " =?= ", compareValue, " = ", similarity);
            maxSimilarity = Math.max(maxSimilarity, similarity);
          });
        } else if (typeof searchvalue === "string") {
          const compareValue = normalizeString(searchvalue);
          const similarity = customSimilarity(searchstr, compareValue);
          console.log(searchstr, " =?= ", compareValue, " = ", similarity);
          maxSimilarity = similarity;
        }
        similars.push({ similarity: maxSimilarity, element: element });
        totalMaxSimilarity = Math.max(totalMaxSimilarity, maxSimilarity);
      });
      similars.sort((objA, objB) =>
        objA.similarity > objB.similarity ? -1 : 1
      );
      console.log("similars: ", similars);
      similars.forEach((obj) => {
        // parent.append(obj.element);
        if (
          (searchstr.length < 4 && obj.similarity < 0.05) ||
          (searchstr.length >= 4 &&
            obj.similarity < Math.min(0.2, totalMaxSimilarity * 0.75))
        ) {
          $(obj.element).hide();
        } else if (totalMaxSimilarity > 0.5 && obj.similarity < 0.5) {
          $(obj.element).hide();
        } else {
          $(obj.element).show();
        }
      });
      this.searchButton.hide();
      this.deleteButton.show();
      this.inputContainer.removeClass("compact");
      window.scrollTo(0, 0);
    }
  }
}

function customSimilarity(s1, s2) {
  let similarity = stringSimilarity(s1, s2);
  similarity += substringInString(s1, s2);
  const simple1 = reduceAlphabet(s1);
  const simple2 = reduceAlphabet(s2);
  let simpleSimilarity = stringSimilarity(simple1, simple2);
  simpleSimilarity += substringInString(simple1, simple2);
  return similarity + 0.1 * simpleSimilarity;
}

function substringInString(s1, s2) {
  if (s2.indexOf(s1) > -1) {
    if (s1.length > 8) {
      return 0.75;
    } else if (s1.length > 5) {
      return 0.5;
    } else if (s1.length > 2) {
      return 0.25;
    } else {
      return 0.125;
    }
  }
  return 0;
}

export function stringSimilarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

function normalizeString(str) {
  if (typeof str === "string") {
    return str
      .toLowerCase()
      .replaceAll(/[àáâãäå]/g, "a")
      .replaceAll(/[èéêë]/g, "e")
      .replaceAll(/[ìíîï]/g, "i")
      .replaceAll(/[òóôõö]/g, "o")
      .replaceAll(/[ùúûü]/g, "u")
      .replaceAll(/[ñ]/g, "n")
      .replaceAll(/[ç]/g, "c")
      .replaceAll(/[ß]/g, "s")
      .replaceAll(/[я]/g, "r");
  }
  return "";
}

function reduceAlphabet(str) {
  return str
    .toLowerCase()
    .replaceAll(/[ub]/g, "v")
    .replaceAll(/[0]/g, "o")
    .replaceAll(/[1y]/g, "i")
    .replaceAll(/[3]/g, "e")
    .replaceAll(/[4]/g, "a")
    .replaceAll(/[7]/g, "t")
    .replaceAll(/[8]/g, "b");
}
