import { isDev } from "./cookies";

const ua = navigator.userAgent || navigator.vendor || window.opera;
export const isInstagram = ua.indexOf("Instagram") > -1 ? true : false;

export function initInstagramLogin() {
  console.log("isDev || isInstagram", isDev, isInstagram);
  if (isDev || isInstagram) {
    document.querySelector(".fb-login-button").remove();
    document.querySelector(".google-login-button").remove();
    const existingMsg = document.getElementById("insta-browser-msg");
    console.log("existingMsg", existingMsg);
    if (existingMsg === null) {
      const container = document.createElement("div");
      container.classList.add("text-center");

      const instaBrowserMsg = document.createElement("p");
      instaBrowserMsg.classList.add("insta-browser-msg");
      instaBrowserMsg.classList.add("alert");
      instaBrowserMsg.classList.add("text-center");
      instaBrowserMsg.setAttribute("id", "insta-browser-msg");
      instaBrowserMsg.innerHTML =
        "Si deseás ingresar con tu cuenta de Facebook o Google debés abrir la página en el navegador<br>(Safari o Chrome por ej.)";
      const loginFormBody = document.querySelector(".login-form-step.step-1");
      container.appendChild(instaBrowserMsg);

      if (isDev) {
        const cleanURL = window.location.href.replace("https://", "");
        const linkGoogle = createLink(
          "googlechrome://" + cleanURL,
          "Abrir en Google Chrome"
        );
        linkGoogle.classList.add("btn", "btn-bordered");
        const linkSafari = createLink(
          "safari-https://" + cleanURL,
          "Abrir en Safari"
        );
        linkSafari.classList.add("btn", "btn-bordered");
        const linkFirefox = createLink(
          "firefox://open-url?url=" + cleanURL,
          "Abrir en Firefox"
        );
        linkFirefox.classList.add("btn", "btn-bordered");
        const linkOpera = createLink(
          "opera-https://" + cleanURL,
          "Abrir en Opera"
        );
        linkOpera.classList.add("btn", "btn-bordered");

        container.appendChild(linkGoogle);
        container.appendChild(linkSafari);
        // container.appendChild(linkFirefox);
        // container.appendChild(linkOpera);
      }
      loginFormBody.prepend(container);
    }
  }
}

function createLink(href, innerHTML) {
  const linkElement = document.createElement("a");
  linkElement.setAttribute("href", href);
  linkElement.innerHTML = innerHTML;
  return linkElement;
}
