import { updateService } from "./updateService";

const allCheckboxes = document.querySelectorAll("[name='check-installments']");
const installmentsSelect = document.getElementById("installments");
const isDebit = document.getElementById("isDebit");

function onReady() {
  if (!installmentsSelect) {
    console.log("installments select not found");
    return;
  }
  console.log("allCheckboxes", allCheckboxes);
  allCheckboxes.forEach((e) =>
    e.addEventListener("change", function () {
      console.log("change installments option", this.value);
      if (this.value === "yes") {
        document.getElementById("installments-active").style.display = "block";
      } else {
        document.getElementById("installments-active").style.display = "none";
      }
      updateInstallments();
    })
  );
  // listen to dom changes in the installments select
  installmentsSelect.addEventListener("change", function () {
    updateInstallments();
  });
  if (isDebit) {
    isDebit.addEventListener("change", function () {
      updateInstallments();
    });
  }
}

export async function updateInstallments() {
  const isDebitValue = isDebit && parseInt(isDebit.value) === 0;
  const checkBoxCheck = document.getElementById("check-installments");
  const checkBoxUncheck = document.getElementById("no-installments");
  const checked = document.getElementById("check-installments").checked;
  if (isDebitValue) {
    checkBoxCheck.disabled = true;
    checkBoxCheck.parentNode.style.opacity = 0.2;
    checkBoxCheck.parentNode.style.filter = "grayscale(1)";
    checkBoxUncheck.checked = true;
    checkBoxUncheck.disabled = true;
  } else {
    checkBoxCheck.disabled = false;
    checkBoxCheck.parentNode.style.opacity = 1;
    checkBoxCheck.parentNode.style.filter = "none";
    checkBoxUncheck.disabled = false;
  }
  const enabled = checked && !isDebitValue;
  const firstOption = installmentsSelect.querySelector("option:first-child");
  const otherOptions = installmentsSelect.querySelectorAll(
    "option:not(:first-child)"
  );
  if (enabled && window.savedcard) {
    console.log("updateServiceFromInstallments 2", isDebitValue);
    updateServiceFromInstallments(2);
    await window.getInstallments();
  } else {
    console.log("updateServiceFromInstallments isDebitValue", isDebitValue);
    updateServiceFromInstallments(isDebitValue ? 0 : enabled ? 2 : 1);
  }
  if (enabled && otherOptions.length > 0) {
    if (firstOption.selected) {
      firstOption.selected = false;
      otherOptions[0].selected = true;
    }
    firstOption.setAttribute("disabled", "disabled");
    firstOption.style.display = "none";
    otherOptions.forEach((option) => {
      option.removeAttribute("disabled");
      option.style.display = "block";
    });
  } else {
    if (!firstOption.selected) {
      firstOption.selected = true;
    }
    firstOption.removeAttribute("disabled");
    firstOption.style.display = "block";
    otherOptions.forEach((option) => {
      option.selected = false;
      option.setAttribute("disabled", "disabled");
      option.style.display = "none";
    });
  }
  updateServiceFromInstallments();
}

// Run the function when the document is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", onReady);
} else {
  onReady();
}

async function updateServiceFromInstallments(forceInstallments) {
  const installmentsDom = document.getElementById("installments");
  const installments =
    typeof forceInstallments !== "undefined" && !isNaN(forceInstallments)
      ? forceInstallments
      : installmentsDom.value;
  console.log("updateServiceFromInstallments", installments);
  const eventid = document.getElementById("eventid").value;
  updateService(eventid, 1, installments);
}
