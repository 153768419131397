/**
 * Creates and submit a form dynamically with data passed as parameters
 * @param {string} action
 * @param {string} method
 * @param {object[]} inputs an array of {name,value} objects
 */
export default function formSubmit(action, method, inputs) {
  const form = document.createElement("form");
  form.action = action;
  form.method = method;

  inputs.forEach((input) => {
    const element = document.createElement("input");
    element.name = input.name;
    element.value = input.value;
    form.appendChild(element);
  });
  document.body.appendChild(form);
  form.submit();
}
