// on document ready
$(function () {
  const container = $("#event-map-container");
  if (container.length > 0) {
    const svg = container.find("svg");
    const elementsWithEtid = svg.find("[data-etid]");
    const allElements = svg.find(
      "path:not([data-etid]), circle:not([data-etid]), rect:not([data-etid]), polygon:not([data-etid]), polyline:not([data-etid])"
    );
    elementsWithEtid.each(function (i, ele) {
      const etidsArr = ($(ele).data("etid").toString() || "").split(",");
      let rows = [];
      etidsArr.forEach(function (etid) {
        const ticketRow = $(".row.ticket.ticket-etid-" + etid);
        if (ticketRow.length > 0) {
          rows.push(ticketRow);
          $(ele).on("click", function (e) {
            e.stopPropagation();
            console.log("click", e.target);
            if (ticketRow.length > 0) {
              // ticketRow.find(".btn-plus").click();
              // scroll to ticket row
              $("html, body").animate(
                {
                  scrollTop: ticketRow.offset().top - 100,
                },
                500
              );
            } else {
              alert("no encontramos el ticket correspondiente");
            }
          });
        }
      });
      if (rows.length > 0) {
        $(ele).attr("title", "etid: " + etidsArr.join(", "));
        $(ele).css("cursor", "pointer");
        $(ele).on("mouseover", function (e) {
          e.stopPropagation();
          console.log("mouseover", e.target);
          allElements.each(function (i, singleEle) {
            styleWithBackup($(singleEle), { opacity: 0.5 });
          });
          elementsWithEtid.each(function (i, singleEle) {
            etidsArr.forEach(function (etid) {
              if (
                ($(singleEle).data("etid").toString() || "")
                  .split(",")
                  .includes(etid)
              ) {
                styleRecursive($(singleEle), {
                  opacity: 1,
                });
              } else {
                styleRecursive($(singleEle), {
                  opacity: 0.5,
                });
              }
            });
          });
          rows.forEach(function (ticketRow) {
            if (ticketRow.length > 0) {
              ticketRow.addClass("map-hover");
            }
          });
          infoWindow(etidsArr, e);
        });
        $(ele).on("mouseout", function (e) {
          e.stopPropagation();
          allElements.each(function (i, singleEle) {
            restoreStyleBackup($(singleEle));
          });
          elementsWithEtid.each(function (i, singleEle) {
            styleRecursive($(singleEle), {
              opacity: 1,
            });
          });
          rows.forEach(function (ticketRow) {
            if (ticketRow.length > 0) {
              ticketRow.removeClass("map-hover");
            }
          });
          container.find(".info-window").remove();
        });
      }
    });
  }

  /**
   * Create a floating window with ticket info over the mouse position
   * @param {*} etid
   * @param {*} event
   * @returns
   */
  function infoWindow(etidsArray, event) {
    let html = `<div class="info-window">
      <div class="info-window-header">
        <div class="info-window-header-title">`;
    let countRows = 0;
    etidsArray.forEach(function (etid) {
      const ticketRow = $(".row.ticket.ticket-etid-" + etid);
      if (ticketRow.length > 0) {
        if (!ticketRow.hasClass("tickets-soldout")) {
          const data = ticketRow.data("ticket");
          html += `<p class="mb-0">
          <strong>${data.name}</strong><br>
          ${data.description}<br>
          $${data.price}</p>`;
          countRows++;
        }
      }
    });
    if (countRows == 0) {
      html += `<p>No hay tickets disponibles para esta zona</p>`;
    }
    html += `</div>
      </div>
    </div>`;

    const infoWindow = $(html);

    infoWindow.css("position", "absolute");
    // get mouse position
    const posX = event.pageX - container.offset().left;
    const posY = event.pageY - container.offset().top;
    // set position
    infoWindow.css("left", posX);
    infoWindow.css("top", posY);
    infoWindow.css("background", "#ffffffd0");
    infoWindow.css("padding", "1rem");
    infoWindow.css("border-radius", "1rem");
    infoWindow.css("pointer-events", "none");
    infoWindow.css("box-shadow", "0 0 10px rgba(0,0,0,0.5)");
    container.append(infoWindow);
  }

  function styleRecursive(element, style) {
    element.css(style);
    element.children().each(function (i, child) {
      styleRecursive($(child), style);
    });
  }

  function getStyleArrayFromElement(element) {
    const style = element.attr("style");
    if (style) {
      return style
        .split(";")
        .map((ele) =>
          ele
            .trim()
            .split(": ")
            .filter((ele) => ele !== "")
        )
        .filter((arr) => arr.length > 0);
    }
    return [];
  }

  function assignArrayStyle(arr, element) {
    if (arr) {
      return arr.forEach((subArr) => element.css(subArr[0], subArr[1]));
    }
    return [];
  }

  function styleWithBackup(element, style) {
    const styleArray = getStyleArrayFromElement(element);
    // console.log("getStyleArray", styleArray);
    if (typeof element.data("styleBackup") === "undefined") {
      element.data("styleBackup", styleArray);
    }
    element.css(style);
    element.children().each(function (i, child) {
      styleWithBackup($(child), style);
    });
  }

  function restoreStyleBackup(element) {
    // console.log("assignArrayStyel", element.data("styleBackup"), element);
    element.removeAttr("style");
    assignArrayStyle(element.data("styleBackup"), element);
    element.children().each(function (i, child) {
      restoreStyleBackup($(child));
    });
  }
});
