export const apiCall = (
  url,
  data = {},
  {
    method = "post",
    dataType = "json",
    success = () => {},
    error = (e1, e2, e3) => console.warn(e1, e2, e3),
    complete = () => {},
  }
) => {
  $.ajax({
    url: url,
    method: method,
    dataType: dataType,
    data: data,
    success: success,
    complete: complete,
    error: error,
  });
};
