import { apiCall } from "./api";
import { customAlert } from "./ui/alert";
import TickyGhostImage from "../assets/images/ticky-ghost.png";
import User from "./user";

console.log(TickyGhostImage);

const user = User.getInstance();
const QR_GENERATE_URL = "https://api.entraste.com/frontend/V1/qr?qr=";

/***
 * UX functions
 */

var checkoutBtnDom = document.getElementById("checkout-btn");
//Handle transitions
if (checkoutBtnDom) {
  checkoutBtnDom.addEventListener("click", function () {
    $(".shopping-cart").fadeOut(500);
    setTimeout(() => {
      $(".container_payment").show(500).fadeIn();
    }, 500);
  });
}

const goBackDom = document.getElementById("go-back");
if (goBackDom) {
  goBackDom.addEventListener("click", function () {
    $(".container_payment").fadeOut(500);
    setTimeout(() => {
      $(".shopping-cart").show(500).fadeIn();
    }, 500);
  });
}

//Handle price update
function updatePrice() {
  let quantity = document.getElementById("quantity").value;
  let unitPrice = document.getElementById("unit-price").innerHTML;
  let amount = parseInt(unitPrice) * parseInt(quantity);
  let currency =
    document.getElementById("currency") &&
    document.getElementById("currency").value !== "UYU"
      ? document.getElementById("currency").value
      : "$";

  document.getElementById("cart-total").innerHTML = currency + " " + amount;
  document.getElementById("summary-price").innerHTML =
    currency + " " + unitPrice;
  document.getElementById("summary-quantity").innerHTML = quantity;
  document.getElementById("summary-total").innerHTML = currency + " " + amount;
  document.getElementById("amount").value = amount;
  console.log("updatePrice", currency, amount);
}
const quantityDom = document.getElementById("quantity");
if (quantityDom) {
  quantityDom.addEventListener("change", updatePrice);
  updatePrice();
}

const descriptionDom = document.getElementById("description");
//Retrieve product description
if (descriptionDom) {
  var productDescription = document.getElementById("product-description");
  if (productDescription) {
    descriptionDom.value = productDescription.innerHTML;
  }
}

export function updateTicketPrice() {
  var totalPrice = 0;
  var totalQuant = 0;
  var currencyInput = document.getElementById("currency");
  var currency = "UYU";
  if (currencyInput) {
    currency = currencyInput.value;
  }
  $(".ticket").each((i, ticketDom) => {
    var ticketData = $(ticketDom).data("ticket");
    if (ticketData) {
      if (
        typeof ticketData.currency !== "undefined" &&
        ticketData.currency !== "" &&
        ticketData.currency !== "UYU"
      ) {
        console.log(ticketData.currency);
        currency = ticketData.currency;
      }

      var select = $(ticketDom).find(".quantity");
      var quantVal = select.find("option:selected").val().split("|");
      var quant = parseFloat(quantVal[0]);
      totalPrice += quant * parseFloat(ticketData.price);
      totalQuant += quant;
      var maxQuant = parseInt(ticketData.mquantity);
      if (quant === maxQuant) {
        $(ticketDom).find(".btn.btn-plus").prop("disabled", true);
      } else {
        $(ticketDom).find(".btn.btn-plus").prop("disabled", false);
      }
      if (quant === 0) {
        $(ticketDom).find(".btn.btn-minus").prop("disabled", true);
      } else {
        $(ticketDom).find(".btn.btn-minus").prop("disabled", false);
      }
    }
  });
  var form = $("#tickets-form");
  var globalMaxQty = Number.MAX_SAFE_INTEGER;

  if (form.data("globalqty")) {
    globalMaxQty = parseInt(form.data("globalqty"));
  }
  var rrppinfo = $("#rrppinfo");
  if (rrppinfo.length > 0) {
    var rrppmax = parseInt(rrppinfo.data("rrppqty"));
    globalMaxQty = Math.min(globalMaxQty, rrppmax);
  }

  var diff = globalMaxQty - totalQuant;

  if (totalQuant > 0) {
    $(
      ".tickets-form .total, .tickets-form .btn-primary, .tickets-form .product-suggestions"
    ).show();
    if (diff !== null) {
      if (diff <= 0) {
        $(".tickets-form").addClass("disable-plus");
      } else {
        $(".tickets-form").removeClass("disable-plus");
      }
      if (diff === 0) {
        $(".ticket .btn.btn-plus").prop("disabled", true);
        $(".global-qty-msg").html(
          '<span class="msg-max">Haz seleccionado el máximo de entradas disponibles</span>'
        );
        $(".tickets-form button[type=submit]").prop("disabled", false);
        $(".tickets-form button[type=submit]").removeClass("disabled");
      } else if (diff < 0) {
        alert("Has superado el límite de entradas");
        $(".global-qty-msg").html(
          '<span class="msg-exceed">Has superado el límite de entradas disponibles. Por favor, selecciona <strong>hasta ' +
            globalMaxQty +
            "</strong>"
        );
        $(".tickets-form button[type=submit]").prop("disabled", true);
        $(".tickets-form button[type=submit]").addClass("disabled");
      } else {
        $(".global-qty-msg").html(
          '<span class="msg-max">Haz seleccionado ' +
            totalQuant +
            " entrada" +
            (totalQuant > 1 ? "s" : "") +
            " de " +
            globalMaxQty +
            " disponible" +
            (globalMaxQty > 1 ? "s" : "") +
            "</span>"
        );
        $(".tickets-form button[type=submit]").prop("disabled", false);
        $(".tickets-form button[type=submit]").removeClass("disabled");
      }
      $(".ticket select.quantity").each(function (j, ticketSelect) {
        var ticketValue = parseInt(
          $(ticketSelect).find("option:selected").val().split("|")[0]
        );
        var ticketDiff = diff + ticketValue;
        $(ticketSelect)
          .find("option")
          .each(function (i, opt) {
            var val = parseInt($(opt).val().split("|")[0]);
            if (val > ticketDiff) {
              $(opt).attr("disabled", "disabled");
            } else {
              $(opt).removeAttr("disabled");
            }
          });
      });
    }
  } else {
    if (globalMaxQty !== null) {
      $(".global-qty-msg").html(
        "Puedes adquirir hasta " +
          globalMaxQty +
          " entrada" +
          (globalMaxQty > 1 ? "s" : "")
      );
    }
    $(
      ".tickets-form .total, .tickets-form .btn-primary, .tickets-form .product-suggestions"
    ).hide();
  }
  $(".total").text(
    (currency !== "UYU" ? currency : "$") + totalPrice.toFixed(2)
  );
  console.log("updateTicketPrice", currency, totalPrice);
}

if (window.isEmbed) {
  var eventURL = window.location.href.replace("/embed", "/evento");
  $(document).on("change", ".ticket .quantity", function () {
    // open event page in new tab
    window.open(eventURL, "_blank");
  });
} else {
  $(document).on("change", ".ticket .quantity", updateTicketPrice);
}

$(document).on("click", "#login-modal .step-button-register", function (e) {
  var btn = $(e.currentTarget);
  var modal = btn.closest(".modal");
  modal.find(".step-1").hide();
  modal.find(".step-2").show();
});

function checkURLMessage() {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var msg = url.searchParams.get("msg");
  url.searchParams.delete("msg");
  var action = url.searchParams.get("action");
  url.searchParams.delete("action");
  if (msg !== null && msg !== "") {
    switch (action) {
      case "login":
        $("#login-modal .error-msg").text(msg);
        $("#login-modal .error-msg").show();
        $("#login-modal").modal("show");
        break;
      default:
        alert(msg);
        break;
    }
  }
  if (url.href !== url_string) {
    window.history.pushState("", "", url.href);
  }
}

$(document).ready(function () {
  updateTicketPrice();
  checkURLMessage();
});

$(document).on("submit", "form#tickets-form", function (event) {
  var form = $(event.currentTarget);
  var serArr = form.serializeArray();
  var missingLogin = false;

  serArr.forEach(function (entry) {
    if (entry.name === "username") {
      if (entry.value === "") {
        missingLogin = true;
      } else {
        $("#buy-not-logged-modal").find("[name=firstname]").val(entry.value);
      }
    }
    if (false && entry.name === "usersurname") {
      if (entry.value === "") {
        missingLogin = true;
      } else {
        $("#buy-not-logged-modal").find("[name=surname]").val(entry.value);
      }
    }
    if (entry.name === "useremail") {
      if (entry.value === "") {
        missingLogin = true;
      } else {
        $("#buy-not-logged-modal").find("[name=email]").val(entry.value);
      }
    }
    if (false && entry.name === "userphone") {
      if (entry.value === "") {
        missingLogin = true;
      } else {
        $("#buy-not-logged-modal").find("[name=phone]").val(entry.value);
      }
    }
  });
  if (missingLogin) {
    event.preventDefault();
    var modal = $("#buy-not-logged-modal");
    if (modal.length > 0) {
      modal.modal("show");
    }
    return false;
  }
  var totalQty = 0;
  form.find("select.quantity").each((i, ele) => {
    console.log($(ele).val());
    const qtyArr = $(ele).val().split("|");
    if (qtyArr.length === 2) {
      totalQty += parseInt(qtyArr[0]);
    }
  });
  if (totalQty === 0) {
    event.preventDefault();
    document.location.reload();
    return false;
  }
});

$(document).on("click", ".forgot-pass-btn", function (event) {
  event.preventDefault();
  apiCall(
    "/actions/forgotpass",
    { email: $("#login-email").val() },
    {
      success: (r) => {
        if (r.status && r.status === "success") {
          alert(r.msg);
        }
      },
    }
  );
  return false;
});

$(document).on("input", "#login-email", function (event) {
  var modal = $(event.currentTarget).closest(".modal");
  modal.find(".step-button-login").prop("disabled", false);
  modal.find(".step-button-register").prop("disabled", false);
  var passFormGroup = modal.find(".login-input-pass");
  passFormGroup.hide();
  passFormGroup.find("input").prop("disabled", true);
});

$(document).on("input", "input", function (event) {
  var input = $(event.currentTarget);
  var clearBtn = input.siblings(".btn-input-clear");
  if (clearBtn.length > 0) {
    if (input.val() !== "") {
      clearBtn.show();
    } else {
      clearBtn.hide();
    }
  }
});

$(document).on("click", ".btn-input-clear", function (e) {
  var btn = $(e.currentTarget);
  var input = btn.siblings("input");
  input.val("");
  input.trigger("change");
  input.trigger("input");
  btn.hide();
});

$(document).on("click", "#buy-not-logged-modal .step-button", function (event) {
  var btn = $(event.currentTarget);
  var modal = btn.closest(".modal");
  var stepContainer = btn.closest(".login-form-step");
  var currentStep = stepContainer.data("step");
  var inputs = stepContainer.find("input");
  var noErrors = true;

  console.log("buy not logged step button click", currentStep);

  btn.prop("disabled", true);
  btn.addClass("loading");

  inputs.each(function (i, input) {
    var name = $(input).attr("name");
    var val = $(input).val().trim();
    console.log(currentStep, val, name);
    if (val !== "") {
      if (name === "firstname") {
        $("#tickets-form input[name=username]").val(val);
        console.log("set username: ", val);
      } else {
        $("#tickets-form input[name=user" + name + "]").val(val);
        console.log("set user" + name + ": ", val);
      }
    } else {
      if (currentStep === 1) {
        if (!(name === "pass")) {
          noErrors = false;
        }
      }
    }
  });
  if (noErrors) {
    if (currentStep === 1) {
      var email = modal.find("[name=email]").val();
      var passFormGroup = modal.find(".login-input-pass");
      if (!passFormGroup.is(":visible")) {
        apiCall(
          "/actions/checkEmail",
          { email: email },
          {
            success: function (r) {
              if (r && r.hasOwnProperty("status") && r.status === "success") {
                passFormGroup.show();
                passFormGroup.find("input").prop("disabled", false);
                modal.find(".step-button-login").prop("disabled", false);
                modal.find(".step-button-register").prop("disabled", true);
                console.log("mail already exists");
              } else {
                passFormGroup.hide();
                passFormGroup.find("input").prop("disabled", true);
                var newStep = currentStep + 1;
                modal.addClass("step-" + newStep.toString());
                modal.removeClass("step-" + currentStep.toString());
                modal.find(".step-button-login").prop("disabled", true);
                modal.find(".step-button-register").prop("disabled", false);
                console.log("mail doesn't exists");
              }
              btn.removeClass("loading");
            },
            error: function (e1, e2, e3) {
              console.warn(e1, e2, e3);
              btn.prop("disabled", false);
              btn.removeClass("loading");
            },
          }
        );
      } else {
        modal.find(".login-msg").text("");
        modal.find(".login-msg").hide();
        apiCall(
          "/actions/login",
          {
            email: email,
            pass: passFormGroup.find("input").val(),
            type: "json",
          },
          {
            success: function (r) {
              if (r && r.hasOwnProperty("status") && r.status === "success") {
                var newStep = currentStep + 2;
                modal.addClass("step-" + newStep.toString());
                modal.removeClass("step-" + currentStep.toString());
                var nameSplit = r.data.name.split(" ");
                var username = r.data.name;
                var usersurname = "";
                if (nameSplit.length > 1) {
                  username = nameSplit.shift();
                  usersurname = nameSplit.join(" ");
                }
                $("#tickets-form [name=username]").val(username);
                $("#tickets-form [name=usersurname]").val(usersurname);
                $("#tickets-form [name=useremail]").val(r.data.email);
                $("#tickets-form [name=userphone]").val("");
                console.log("login while buying success");
                if ($("#tickets-form").length > 0) {
                  $("#tickets-form").submit();
                } else {
                  window.location.reload();
                }
              } else {
                console.warn(r);
                if (r && r.hasOwnProperty("msg")) {
                  modal.find(".login-msg").text(r.msg);
                  modal.find(".login-msg").show();
                } else {
                  modal.find(".login-msg").text("Hubo un error");
                  modal.find(".login-msg").show();
                }
              }
              btn.prop("disabled", false);
              btn.removeClass("loading");
            },
            error: function (e1, e2, e3) {
              console.warn(e1, e2, e3);
              btn.prop("disabled", false);
              btn.removeClass("loading");
            },
          }
        );
      }
    } else {
      var newStep = currentStep + 1;
      if (stepContainer.hasClass("step-final")) {
        var userData = modal.find("form").serializeArray();
        if (typeof fbq === "function") {
          var fbqParams = {
            content_name: $("#login-email").val(),
            currency: "UYU",
            value: "",
            status: false,
          };
        }
        apiCall("/actions/saveUserData", userData, {
          success: function (r) {
            if (r && r.hasOwnProperty("status") && r.status === "success") {
              modal.addClass("step-" + newStep.toString());
              modal.removeClass("step-" + currentStep.toString());
              fbqParams.status = true;
              console.log("fbq track CompleteRegistration", fbqParams);
              fbq("track", "CompleteRegistration", fbqParams);

              if ($("#tickets-form").length > 0) {
                $("#tickets-form").submit();
              } else {
                window.location.reload();
              }
            } else {
              if (r.hasOwnProperty("msg")) {
                alert(r.msg);
              } else {
                alert("Hubo un error");
              }
              console.log("fbq track CompleteRegistration", fbqParams);
              fbq("track", "CompleteRegistration", fbqParams);
            }
            btn.prop("disabled", false);
            btn.removeClass("loading");
          },
          error: function (e1, e2, e3) {
            console.warn(e1, e2, e3);
            btn.prop("disabled", false);
            btn.removeClass("loading");
            if (typeof fbq === "function") {
              console.log("fbq track CompleteRegistration", fbqParams);
              fbq("track", "CompleteRegistration", fbqParams);
            }
          },
        });
      } else {
        modal.addClass("step-" + newStep.toString());
        modal.removeClass("step-" + currentStep.toString());
      }
    }
  } else {
    var email = modal.find("[name=email]").val();
    if (email === "") {
      modal.find("[name=email]")[0].focus();
      console.log("empty email");
    }
    btn.prop("disabled", false);
    btn.removeClass("loading");
  }
});

$(document).on("click", "#buy-not-logged-modal .back", function (event) {
  var btn = $(event.currentTarget);
  var modal = btn.closest(".modal");
  var stepContainer = modal.find(".login-form-step:visible");
  var currentStep = stepContainer.data("step");
  if (currentStep > 1) {
    modal.addClass("step-" + (currentStep - 1).toString());
    modal.removeClass("step-" + currentStep.toString());
  } else {
    modal.modal("hide");
  }
});
var asking = false;
var timeZoneOffsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000;

export function checkAvailable() {
  apiCall(
    "/actions/checkAvailable",
    {},
    {
      method: "POST",
      dataType: "JSON",
      success: function (r) {
        if (r && r.hasOwnProperty("status") && r.status === "success") {
          $(".counter").data("time", r.counter);
          asking = false;
          updateCounter();
        } else {
          window.location.href = window.eventRedirect;
        }
      },
      error: function (e1, e2, e3) {
        window.location.href = window.eventRedirect;
        console.warn(e1, e2, e3);
      },
      complete: function (r) {
        console.log("complete checkAvailable", r);
      },
    }
  );
}

function updateCounter() {
  $(".counter").each(function (i, counter) {
    var targetTime = parseInt($(counter).data("time"));
    if (targetTime) {
      var timeLeftDate = new Date();
      var diff = targetTime * 1000 - Date.now();
      if (diff < 0) {
        if (!asking) {
          asking = true;
          customAlert(
            "El tiempo ha expirado, deseas verificar la disponibilidad nuevamente?",
            {
              msg: "Verificar",
              callback: checkAvailable,
            },
            {
              msg: "Cancelar",
              callback: function () {
                window.location.href = window.eventRedirect;
              },
            }
          );
        }
      } else {
        timeLeftDate.setTime(diff + timeZoneOffsetMilliseconds);
        $(counter).text(
          (timeLeftDate.getMinutes() > 0
            ? timeLeftDate.getMinutes() + "min "
            : "") +
            timeLeftDate.getSeconds() +
            "seg"
        );
      }
    }
  });
}
updateCounter();
setInterval(updateCounter, 1000);

$(document).on("click", "[data-toggle=readonly]", function (e) {
  var btn = $(e.currentTarget);
  var target = $(btn.data("target"));
  if (target.hasClass("readonly-form-readonly")) {
    target.find("input, select, textarea").each(function (i, ele) {
      $(ele).prop("readonly", false);
    });
    target.removeClass("readonly-form-readonly");
  } else {
    target.find("input, select, textarea").each(function (i, ele) {
      $(ele).prop("readonly", true);
    });
    target.addClass("readonly-form-readonly");
  }
});

function togglePaymentMethod(event) {
  $("#payment-section [name=paymentMethod]:checked").each(function (i, ele) {
    console.log($(ele).val(), $(ele).text());
    var pmid = $(ele).val();
    $(".payment-method-body").each(function (j, paymentMethodContainer) {
      var container = $(paymentMethodContainer);
      console.log(pmid);
      var pmids = container.data("pmid").toString();
      console.log(pmids);
      if (pmids.split(",").indexOf(pmid.toString()) > -1) {
        container.show();
      } else {
        container.hide();
      }
    });
  });
  getService(event.target.value);
}

function getService(pmid, installments = 0) {
  const eventid = $("[name=eventid]").val();
  // call /actions/service
  $.ajax({
    url: "/actions/service",
    method: "POST",
    dataType: "json",
    data: { pmid, eventid, installments },
    success: (response) => {
      if (response?.status === "success") {
        const data = response.data;
        $("#pmid-" + pmid + "-service").text(data.service_value);
        $("#pmid-" + pmid + "-amount").text(data.total_w_service);
      }
    },
    error: (e1, e2, e3) => {
      console.log(e1, e2, e3);
    },
  });
}

$(document).on(
  "change",
  "#payment-section [name=paymentMethod]",
  togglePaymentMethod
);

$(window).on("scroll", function (e) {
  var scroll = $(window).scrollTop();
  $(".to-fixed").each(function (i, eleDom) {
    var ele = $(eleDom);
    var offset =
      $(window).width() < 768 ? ele.data("mobileoffset") : ele.data("offset");
    var container = ele.closest(".to-fixed-container");
    if (scroll > container.offset().top + offset && !ele.hasClass("fixed")) {
      var containerHeight = container.height();
      var containerWidth = container.width();
      container.css("height", containerHeight);
      ele.css("width", containerWidth);
      ele.addClass("fixed");
    }
    if (scroll <= container.offset().top + offset && ele.hasClass("fixed")) {
      container.css("height", "");
      ele.css("width", "");
      ele.removeClass("fixed");
    }
  });
});

$(document).ready(function () {
  if ($("#card-container").length > 0) {
    var card = new Card({
      form: "#paymentForm",
      container: "#card-container",
      formSelectors: {
        nameInput: "#cardholderName",
        numberInput: "#cardNumber",
        expiryInput: "#cardExpirationMonth, #cardExpirationYear",
        cvcInput: "#securityCode",
      },
      width: 300,
      formatting: true,
      messages: {},
    });
  }
  if ($("#addcard-card-container").length > 0) {
    var card = new Card({
      form: "#addCardForm",
      container: "#addcard-card-container",
      formSelectors: {
        nameInput: "#addcard-cardholderName",
        numberInput: "#addcard-cardNumber",
        expiryInput:
          "#addcard-cardExpirationMonth, #addcard-cardExpirationYear",
        cvcInput: "#addcard-securityCode",
      },
      width: 300,
      formatting: true,
      messages: {},
    });
  }

  $(".event-tickets-container").on("shown.bs.collapse", function (event) {
    var eventTicketsContainer = $(event.currentTarget);
    eventTicketsContainer
      .find(".tickets-container")
      .each(function (i, sliderContainer) {
        if (typeof $(sliderContainer).slick !== "undefined") {
          try {
            $(sliderContainer).slick("unslick");
            console.log("unslick sliderContainer");
          } catch (error) {
            console.log("unslick error", error);
          }
        }
        $(sliderContainer).slick({
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "60px",
        });
      });
  });

  $(".tickets-container").each(function (i, sliderContainer) {});

  $(".btn-show-qr").on("click", (event) => {
    const btn = $(event.currentTarget);
    const ticketData = btn.data("ticket");
    const tivid = ticketData.tivid;
    const container = btn.closest(".qr-container");
    const loader = $("<img>");
    loader.attr("src", "/sc/images/ajax-loader.gif");
    loader.addClass("loader");
    btn.html(loader);

    console.log("show qr", ticketData);

    if (
      typeof ticketData.qrqty !== "undefined" &&
      Array.isArray(ticketData.qrqty)
    ) {
      generateInviteQRs(ticketData.itid, ticketData, container);
    } else {
      $.ajax({
        url: "/actions/ticket",
        method: "post",
        dataType: "json",
        data: { tivid: tivid },
        success: (response) => {
          if (response?.status === "success") {
            console.log("ticket response", response);
            /** @type TicketResponseData */
            const data = response.data;
            if (data?.status === 6) {
              console.log("QRs are not available yet");
            }
            if (data?.tickets && data?.status) {
              data.tickets = data.tickets.map((ticket) => ({
                ...ticket,
                visible: data?.status !== 6,
              }));
            }
            generateQRs(tivid, data, container);
          }
        },
        error: (e1, e2, e3) => {
          console.log(e1, e2, e3);
        },
      });
    }
  });

  $(".single-ticket").each((index, ticket) => {
    const ticketDiv = $(ticket);
    const tivid = ticketDiv.data("tivid");

    if (tivid) {
      updateTicketsStatus(tivid, ticketDiv);
      setInterval(() => {
        updateTicketsStatus(tivid, ticketDiv);
      }, 10000);
    }
    if ($(ticket).hasClass("single-ticket-status-3")) {
      ticketDiv.closest(".row[data-ticket]").hide();
    }
  });
});

function updateTicketsStatus(tivid, ticketDiv) {
  if (ticketDiv.closest(".event-tickets-container.show").length > 0) {
    $.ajax({
      url: "/actions/ticketstatus",
      data: { tivid: tivid },
      dataType: "JSON",
      method: "POST",
      success: (response) => {
        if (response?.status === "success") {
          if (response?.data?.status) {
            ticketDiv.removeClass("single-ticket-status-1");
            ticketDiv.removeClass("single-ticket-status-2");
            ticketDiv.removeClass("single-ticket-status-3");
            ticketDiv.removeClass("single-ticket-status-4");
            ticketDiv.addClass(`single-ticket-status-${response.data.status}`);
          }
        }
      },
      error: (e1, e2, e3) => {
        console.log(e1, e2, e3);
      },
    });
    // } else {
    //   console.log("not updating ticket status, because it is not visible");
  }
}

/**
 *
 * @param {string} tivid - tivid
 * @param {TicketData} ticketData
 * @param {jQuery} container - the container element
 */
function generateQRs(tivid, orderData, container) {
  container.html("");
  const showQRbtn =
    '<button type="button" class="btn btn-show-qr btn-primary">Mostrar QR</button>';
  if (orderData.tickets.length === 0) {
    showQRbtn.data("ticket", orderData);
    container.append(showQRbtn);
  } else {
    orderData.tickets.forEach((singleTicket) => {
      if (singleTicket.tivid === tivid) {
        const ticketContainer = $("<div class='tickets-container'>");
        ticketContainer.addClass(`ticket-status-${singleTicket.status}`);
        if (singleTicket?.qr && singleTicket.qr.length > 0) {
          singleTicket.qr.forEach((singleQr, qrIndex) => {
            const qrDiv = initSingleQR(qrIndex, singleQr, singleTicket);
            ticketContainer.append(qrDiv);
          });
          $(ticketContainer).slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "60px",
          });
        } else {
          renderHiddenQR(orderData, singleTicket, ticketContainer);
        }
        container.append(ticketContainer);
      }
    });
  }
}

/**
 * Renders the hidden QR message
 * @param {object} ticketData
 * @returns {void}
 */
function renderHiddenQR(orderData, ticketData, ticketContainer) {
  var container = $("<div>");
  container.addClass("hide-qr-container");
  const ticketName = $("<h3>");
  ticketName.text(ticketData.name);
  container.append(ticketName);
  const ticketDescription = $("<p>");
  ticketDescription.text(ticketData.description);
  container.append(ticketDescription);

  const tickyImage = $("<img>");
  tickyImage.attr("src", TickyGhostImage);
  tickyImage.addClass("ticky");

  container.append(tickyImage);
  if (ticketData.visible) {
    container.append(
      $("<h4>Los QR aparecerán aquí cuando el ticket esté pago</h4>")
    );
    // container.append(
    //   $(
    //     "<p>Para evitar reventas, el código QR permanecerá oculto hasta " +
    //       orderData.qrhide +
    //       "hs antes del evento.</p>"
    //   )
    // );
    ticketContainer.append(showQRbtn);
  } else {
    container.append(
      $(
        "<h4>Los QR aparecerán aquí " +
          orderData.qrhide +
          "hs antes del evento</h4>"
      )
    );
    // container.append(
    //   $(
    //     "<p>Para evitar reventas, el código QR permanecerá oculto hasta " +
    //       orderData.qrhide +
    //       "hs antes del evento.</p>"
    //   )
    // );
  }
  const ticketInfo = $("<p>");
  ticketInfo.html(
    `Cantidad: ${ticketData.qty}<br>Precio: $ ${ticketData.total}`
  );
  container.append(ticketInfo);
  ticketContainer.append(container);
  // const debugTicketData = $("<pre>");
  // debugTicketData.text(JSON.stringify(ticketData, null, " "));
  // ticketContainer.append(debugTicketData);
}

/**
 *
 * @param {number} qrIndex - the index of the qr in the qr array
 * @param {QR} qr - the qr data
 * @param {Ticket} ticket - the single ticket data
 * @returns {jQuery} the div containing the qr image
 */
function initSingleQR(qrIndex, qr, ticket) {
  const div = $('<div style="width: 300px;">');
  const ticketText = $("<p>");
  ticketText.html(`<strong>${ticket.name}</strong><br>${ticket.description}`);
  const imgQr = $('<img style="width: 300px; margin: 0 auto;">');
  const qrURL = `${QR_GENERATE_URL}${qr.qr}`;
  imgQr.attr("src", qrURL);
  const subText = $("<p>");
  subText.text(`Código ${qrIndex + 1} de ${ticket.qr.length}`);
  const qStatusText = $("<p class='qstatus-text'>");
  qStatusText.text(getQStatusText(qr.qstatus));

  div.append(ticketText);
  div.append(imgQr);
  div.append(qStatusText);
  div.append(subText);

  const isMujer = ticket.name.toLowerCase().indexOf("mujer") > -1;
  let additionalClasses = "";
  if (isMujer) {
    additionalClasses = "single-qrmujer";
  }

  const isCortesia =
    ticket.name.toLowerCase().indexOf("cortesia") > -1 ||
    ticket.name.toLowerCase().indexOf("cortesía") > -1;
  if (isCortesia) {
    additionalClasses += " single-qrcortesia";
  }

  div.addClass(`single-qr single-qstatus-${qr.qstatus} ${additionalClasses}`);
  const interval = setInterval(() => {
    getQrStatus(qr.tqrid, div, additionalClasses);
  }, 5000);
  div.data("interval", interval);
  return div;
}

/**
 *
 * @param {number} qrIndex - the index of the qr in the qr array
 * @param {QR} qr - the qr code
 * @param {Ticket} ticket - the single ticket data
 * @returns {jQuery} the div containing the qr image
 */
function initInviteSingleQR(qrIndex, qr, ticket, total) {
  console.log("initInviteSingleQR(qr, ticket)", qr, ticket);
  const div = $('<div style="width: 364px;margin: 0 auto;">');
  const ticketText = $("<p>");
  ticketText.html(`<strong>${ticket.name}</strong><br>${ticket.description}`);
  const imgQr = $('<img style="width: 300px; margin: 0 auto;">');
  const qrURL = `${QR_GENERATE_URL}${qr}`;
  imgQr.attr("src", qrURL);
  const subText = $("<p>");
  subText.text(`Código ${qrIndex + 1} de ${total}`);
  const qStatusText = $("<p class='qstatus-text'>");
  qStatusText.text(getQStatusText(ticket.qstatus));

  div.append(ticketText);
  div.append(imgQr);
  div.append(qStatusText);
  div.append(subText);

  let additionalClasses = "";
  const isMujer = ticket.name.toLowerCase().indexOf("mujer") > -1;
  if (isMujer) {
    additionalClasses = "single-qrmujer";
  }
  const isCortesia =
    ticket.name.toLowerCase().indexOf("cortesia") > -1 ||
    ticket.name.toLowerCase().indexOf("cortesía") > -1;
  if (isCortesia) {
    additionalClasses += " single-qrcortesia";
  }

  div.addClass(
    `single-qr single-qstatus-${ticket.qstatus} ${additionalClasses}`
  );
  const interval = setInterval(() => {
    getQrStatus(ticket.tqrid, div, additionalClasses);
  }, 5000);
  div.data("interval", interval);
  return div;
}

function getQrStatus(tqrid, qrDiv, additionalClasses = "") {
  $.ajax({
    url: "/actions/qrstatus",
    method: "POST",
    dataType: "JSON",
    data: { tqrid: tqrid },
    success: /**
     * @param {QRStatusResponse} response -
     */ (response) => {
      if (response && response.hasOwnProperty("data")) {
        const qstatus = response.data.qstatus;
        const qstatusText = getQStatusText(qstatus);

        qrDiv.find(".qstatus-text").text(qstatusText);

        qrDiv.removeClass();
        qrDiv.addClass(
          `single-qr single-qstatus-${qstatus} ${additionalClasses}`
        );
      }
      if (response.hasOwnProperty("msg") && response.msg === "Not logged") {
        document.location.reload();
      }
    },
    error: (e1, e2, e3) => {
      console.log("error getting qrstatus", tqrid, e1, e2, e3);
    },
  });
}

/**
 *
 * @param {"1"|"2"|"3"|"4"} qstatus
 * @returns {string} the text representing the qstatus
 */
function getQStatusText(qstatus) {
  switch (qstatus) {
    case "1":
      return "Pendiente";
    case "2":
      return "Sin usar";
    case "3":
      return "Anulado";
    case "4":
      return "Utilizada";
    default:
      break;
  }
  return "";
}

/**
 *
 * @param {string} itid - itid
 * @param {TicketData} ticketData
 * @param {jQuery} container - the container element
 */
function generateInviteQRs(itid, ticketData, container) {
  container.html("");
  console.log("(itid, ticketData, container)", itid, ticketData, container);
  const showQRbtn =
    '<button type="button" class="btn btn-show-qr btn-primary">Mostrar QR</button>';
  if (ticketData.qrqty.length === 0) {
    showQRbtn.data("ticket", ticketData);
    container.append(showQRbtn);
  } else {
    const ticketContainer = $("<div class='tickets-container'>");
    ticketData.qrqty.forEach((singleTicket, index) => {
      if (singleTicket.itid === itid) {
        ticketContainer.addClass(`ticket-status-${singleTicket.qstatus}`);

        const qrDiv = initInviteSingleQR(
          index,
          singleTicket.qr,
          {
            ...singleTicket,
            name: ticketData.tname,
            description: "",
          },
          ticketData.qrqty.length
        );
        ticketContainer.append(qrDiv);
      }
    });
    container.append(ticketContainer);
    $(ticketContainer).slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "60px",
    });
  }
}

/**
 * @typedef {object} TicketData
 * @property {string} tivid
 * @property {string} userid
 * @property {string} date
 * @property {string} pdate
 * @property {string} pinfo
 * @property {string} eventid
 * @property {string} subtotal
 * @property {string} service
 * @property {string} total
 * @property {string} pmid
 * @property {string} pmextra
 * @property {null} invite
 * @property {string} ci
 * @property {string} status
 * @property {Ticket[]} tickets
 */

/**
 * @typedef {object} Ticket
 * @property {string} userid
 * @property {string} date
 * @property {string} etid
 * @property {string} qty
 * @property {string} eventid
 * @property {string} subtotal
 * @property {string} service
 * @property {string} total
 * @property {string} status
 * @property {string} tiid
 * @property {string} tivid
 * @property {QR[]} qr
 */

/**
 * @typedef {object} QR
 * @property {string} tqrid
 * @property {string} userid
 * @property {string} tivid
 * @property {string} qr - the qr code
 * @property {"1"|"2"|"3"|"4"} qstatus - the current status of the qr
 */

/**
 * @typedef {object} QRStatusResponse
 * @property {"success"|"fail"} status status text
 * @property {object} data object containing the response data
 * @property {"1"|"2"|"3"|"4"} data.qstatus the current qstatus of the qr
 */
