$(document).on("click", ".single-event-devolucion header", (e) => {
  const element = $(e.currentTarget).closest(".single-event-devolucion");
  getTicket(element);
});

function getTicket(element, selectedQrs = []) {
  const tivid = element.data("tivid");
  const ticketsContainer = element.find(".devolucion-tickets");
  const loader = $($(".loader").get(0)).clone();
  ticketsContainer.html("");
  ticketsContainer.append(loader);
  $.ajax({
    url: "/actions/ticket",
    method: "POST",
    data: { tivid: tivid },
    dataType: "json",
    success: function (data) {
      if (data.error) {
        alert(data.error);
      } else {
        /*
        example response:
          {
            "status": "success",
            "data": {
              "tivid": "102572",
              "userid": "42721",
              "date": "1678314445",
              "pdate": "1678314445",
              "pinfo": "{\"status\":\"approved\",\"tivid\":102572,\"date\":1678314445}",
              "ainfo": "prueba",
              "eventid": "163",
              "subtotal": "0",
              "service": "0",
              "total": "0",
              "osubtotal": "0",
              "exchange": "0",
              "oexchange": "0",
              "currency": "UYU",
              "cuotas": "1",
              "pmid": "98",
              "pmextra": "gratis",
              "invite": null,
              "ci": "",
              "phone": "+598 93 870 306",
              "invoice": "0",
              "status": "5",
              "start": "1691204100",
              "qrhide": "0",
              "nameseo": "lista-berracas-viernes",
              "qrqty": 1,
              "tickets": [
                {
                  "tckid": "103988",
                  "userid": "42721",
                  "date": "1678314445",
                  "etid": "897",
                  "qty": "1",
                  "eventid": "163",
                  "subtotal": "0",
                  "service": "0",
                  "total": "0",
                  "osubtotal": "0",
                  "exchange": "0",
                  "oexchange": "0",
                  "currency": "UYU",
                  "status": "5",
                  "tiid": "103875",
                  "tivid": "102572",
                  "name": "Lista gratis hasta la 1",
                  "description": "Solo mujeres. de 1 a 1:30 entrada con consumición en boletería ",
                  "qr": [
                    {
                      "tqrid": "76957",
                      "tckid": "103988",
                      "userid": "42721",
                      "tivid": "102572",
                      "qr": "MXM4MDhYS2FLbVgvY2JvSUMzNVlYZ1FyT1JwcDdYNG43dXNISk9nNWp0RT0=",
                      "scan": "0",
                      "qstatus": "3"
                    }
                  ]
                }
              ]
            }
          }
        */
        ticketsContainer.html("");
        data.data.tickets.forEach((ticket, index) => {
          console.log(
            "devolucion ticket",
            ticket.tivid,
            selectedQrs
          );
          const allSelected =
            selectedQrs.length >= ticket.qr.length &&
            selectedQrs.length > 0 &&
            ticket.qr.length > 0 &&
            data.data.tickets.reduce((acc, _ticket) => {
              const allQRsSelected = _ticket.qr.reduce((acc, qr) => {
                console.log(
                  "check",
                  qr.tqrid,
                  selectedQrs.includes(parseInt(qr.tqrid))
                );
                return acc && selectedQrs.includes(parseInt(qr.tqrid));
              }, true);
              console.log("check all", allQRsSelected, acc && allQRsSelected);
              return acc && allQRsSelected;
            }, true);
          ticketsContainer.append(
            renderTicket(data.data, ticket, index, selectedQrs, allSelected)
          );
        });
        console.log("end ticket");
      }
    },
  });
}

function renderTicket(order, ticket, index, selectedQrs, allSelected = false) {
  return `
  ${
    index === 0
      ? `
      <tr class="devolucion-ticket-header" data-tivid="${order.tivid}">
        <td>Orden</td>
        <td title="${order.pmid}" style="text-transform: capitalize;">
          ${order.pmextra} ${allSelected ? "checked" : "not checked"}
        </td>
        <td colspan="2">
          <div>
            <span>Todos</span> <input type="checkbox" name="select-all" class="select-all form-control" ${
              allSelected ? "checked='checked'" : ""
            } />
          </div>
        </td>
      </tr>`
      : ""
  }
      <tr class="devolucion-ticket" data-tivid="${order.tivid}">
        <td>
          <h3>${ticket.name}</h3>
        </td>
        <td colspan="2">
          ${ticket.description}
        </td>
        <td></td>
      </tr>
    ${ticket.qr
      .map(
        (qr, index) => `
      <tr class="devolucion-qr" data-tqrid="${qr.tqrid}">
        <td>
          QR ${index + 1}<small> - ${qr.tqrid}</small>
        </td>
        <td>
          ${qr.tckid} ${
          selectedQrs.includes(parseInt(qr.tqrid)) ? "checked" : "not-checked"
        }
        </td>
        <td>
          ${getQStatusText(qr.qstatus)}
        </td>
        <td>
          <input type="checkbox" name="devolqrs[]" class="select-qr form-control" value="${
            qr.tqrid
          }-${qr.tivid}" ${
          selectedQrs.includes(parseInt(qr.tqrid)) ? "checked='checked'" : ""
        } />
        </td>
      </tr>
    `
      )
      .join("\n")}
  `;
}

/**
 *
 * @param {"1"|"2"|"3"|"4"} qstatus
 * @returns {string} the text representing the qstatus
 */
function getQStatusText(qstatus) {
  switch (qstatus) {
    case "1":
      return "Pendiente";
    case "2":
      return "Sin usar";
    case "3":
      return "Anulado";
    case "4":
      return "Utilizada";
    default:
      break;
  }
  return "";
}

$(document).on("change", ".single-event-devolucion .select-all", function (e) {
  const element = $(e.currentTarget);
  const checked = element.prop("checked");
  const devolucionQrs = element
    .closest(".single-event-devolucion")
    .find(".select-qr");
  devolucionQrs.prop("checked", checked);
});

function updateCheckboxes(container, changedElement) {
  const devolucionQrs = container.find(".select-qr");
  let allChecked = true;
  devolucionQrs.each((index, element) => {
    const el = $(element);
    if (!el.prop("checked")) {
      allChecked = false;
    }
  });
  if (allChecked) {
    container.find(".select-all").prop("checked", true);
  } else {
    container.find(".select-all").prop("checked", false);
  }
}

$(document).on("change", ".single-event-devolucion .select-qr", function (e) {
  const element = $(e.currentTarget);
  const container = element.closest(".single-event-devolucion");
  updateCheckboxes(container, element);
});

$(document).ready(function () {
  /*
  Single event example:
  <div
    id="order-%TIVID%"
    class="single-event-devolucion"
    data-tivid="%TIVID%"
    data-selectedqrs="[%TQRID%]"
  >...</div> */
  $(".single-event-devolucion[data-selectedqrs]").each((index, element) => {
    const container = $(element);
    const selectedQrs = container.data("selectedqrs");
    const tivid = container.data("tivid");
    console.log("selected qrs", tivid, selectedQrs);
    getTicket(container, selectedQrs);
  });
});
