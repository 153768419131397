const debounce = (callback, wait = 1000) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

class ListenForInteraction {
  lastId = Date.now();
  callbacks = {};

  onInteraction = (callback) => {
    this.lastId++;
    this.callbacks[++this.lastId] = callback;
    return;
  };

  handleInteraction = debounce(() => {
    // console.log("Interaction");
    for (let i in this.callbacks) {
      if (typeof this.callbacks[i] === "function") {
        this.callbacks[i]();
      } else {
        delete this.callbacks[i];
      }
    }
  }, 1000);

  constructor() {
    document.body.addEventListener("mousemove", this.handleInteraction);
    document.body.addEventListener("scroll", this.handleInteraction);
    document.body.addEventListener("keydown", this.handleInteraction);
    document.body.addEventListener("click", this.handleInteraction);
    document.body.addEventListener("touchstart", this.handleInteraction);
  }
}

let listenForInteraction = new ListenForInteraction();
const isVideoPlaying = (video) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  );

listenForInteraction.onInteraction(() => {
  // console.log("on interaction callback");
  $(".event-info-title").css("text-decoration", "none");

  $(".event-background-video").each(function (i, videoContainerDOM) {
    const videoContainer = $(videoContainerDOM);
    // console.log(videoContainer);
    const video = videoContainer.find("video");
    if (video.length === 1) {
      if (!isVideoPlaying(video[0])) {
        video[0].play();
        // console.log("video play");
      } else {
        // console.log("video is playing");
      }
    } else {
      // console.log("video not found");
    }
  });
});
