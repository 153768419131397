import { MP_KEY } from "./constants";
import validarCedula from "./utlis/validarCedula";

if (window.creditcardwebview) {
  //REPLACE WITH YOUR PUBLIC KEY AVAILABLE IN: https://developers.mercadopago.com/panel/credentials
  const mp = new MercadoPago(MP_KEY, {
    locale: "es-UY",
  });

  (async function () {
    const identificationTypes = await mp.getIdentificationTypes();
    console.log("identificationTypes", identificationTypes);
    if (Array.isArray(identificationTypes)) {
      const select = $("#docType");
      select.html("");
      identificationTypes.forEach(function (idType) {
        const option = $("<option>");
        option.attr("value", idType.id);
        option.text(idType.name);
        select.append(option);
      });
    }
  })();

  //Proceed with payment
  let doSubmit = false;
  let form = document.getElementById("paymentForm");
  if (form) {
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      $(form).find("button[type=submit]").prop("disabled", true);
      $(form).find("button[type=submit]").addClass("loading");
      getCardToken(event);
      return false;
    });
  }

  function getCardToken(event) {
    event.preventDefault();
    if (!doSubmit) {
      let form = document.getElementById("paymentForm");
      const params = {
        cardNumber: $("#cardNumber").val().replace(/\D/g, ""),
        cardholderName: $("#cardholderName").val(),
        cardExpirationMonth: $("#cardExpirationMonth").val(),
        cardExpirationYear: $("#cardExpirationYear").val(),
        securityCode: $("#securityCode").val(),
        identificationType: $("#docType").val(),
        identificationNumber: $("#docNumber").val(),
      };
      console.log("mercadopago create token", params);
      // window.Mercadopago.createToken(form, setCardTokenAndPay);
      (async function () {
        mp.createCardToken(params)
          .then(function (response) {
            console.log("createCardToken then response", response);
            $(form).find("button[type=submit]").prop("disabled", false);
            $(form).find("button[type=submit]").removeClass("loading");

            setCardTokenAndPay(200, response);
          })
          .catch(function (response) {
            console.warn("createCardToken error: ", response);
            alert("Por favor, verifique los datos");
            let form = document.getElementById("paymentForm");
            $(form).find("button[type=submit]").prop("disabled", false);
            $(form).find("button[type=submit]").removeClass("loading");

            if (
              response.hasOwnProperty("cause") &&
              Array.isArray(response.cause)
            ) {
              response.cause.forEach(showErrors);
            } else if (Array.isArray(response)) {
              response.forEach(showErrors);
            }
          });
      })();
      return false;
    } else {
      alert("doSumbit", doSubmit);
    }
  }

  function setCardTokenAndPay(status, response) {
    console.log("setCardToken and pay", status, response);
    if (status == 200 || status == 201) {
      let form = document.getElementById("paymentForm");
      $(form).find("input[name=token]").remove();
      let card = document.createElement("input");
      const cardObject = {
        name: response.cardholder.name,
        docNumber: response.cardholder.identification.number,
        docType: response.cardholder.identification.type,
        lastdigits: response.last_four_digits,
        expMonth: response.expiration_month,
        expYear: response.expiration_year,
        token: response.id,
      };
      card.setAttribute("name", "token");
      card.setAttribute("type", "hidden");
      card.setAttribute("value", response.id);
      form.appendChild(card);
      doSubmit = true;
      console.log($(form).attr("action"), $(form).serializeArray());
      //form.submit(); //Submit form data to your backend
      $.ajax({
        url: $(form).attr("action"),
        method: $(form).attr("method"),
        dataType: "json",
        data: $(form).serializeArray(),
        success: function (r) {
          if (r && r.hasOwnProperty("status")) {
            console.log(JSON.stringify(r));
            if (r.status === "success") {
              console.log("add card success");
              if (typeof window.ReactNativeWebView !== "undefined") {
                window.ReactNativeWebView.postMessage(JSON.stringify(r));
              }
            } else {
              alert("Hubo un error al agregar la tarjeta");
            }
          }
          $(form).find("button[type=submit]").prop("disabled", false);
          $(form).find("button[type=submit]").removeClass("loading");
          doSubmit = false;
        },
        error: function (e1, e2, e3) {
          console.warn(e1, e2, e3);
          alert("Hubo un error al agregar la tarjeta");
          $(form).find("button[type=submit]").prop("disabled", false);
          $(form).find("button[type=submit]").removeClass("loading");
        },
      });
    } else {
      console.warn("Verify filled data!\n", response);
      alert("Por favor, verifique los datos");
      let form = document.getElementById("paymentForm");
      $(form).find("button[type=submit]").prop("disabled", false);
      $(form).find("button[type=submit]").removeClass("loading");

      if (response.hasOwnProperty("cause") && Array.isArray(response.cause)) {
        response.cause.forEach(showErrors);
      } else if (Array.isArray(response)) {
        response.forEach(showErrors);
      }
    }
  }

  function showErrors(cause) {
    $(".form-group").removeClass("form-group-w-error");
    if (cause.hasOwnProperty("code")) {
      console.warn(cause.code, cause.description);
      switch (cause.code) {
        case "205":
        case "E301":
          var input = $("#cardNumber");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          break;
        case "208":
        case "325":
          var input = $("#cardExpirationMonth");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          break;
        case "209":
        case "326":
          var input = $("#cardExpirationYear");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          break;
        /*case "212":break;// docType cannot be null/empty */
        /*case "322":break;// invalid docType */
        /*case "213":break;// cardholder.document.subtype cannot be null/empty */
        /*case "323":break;// invalid cardholder.document.subtype */
        case "214":
        case "324":
          var input = $("#docNumber");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              inputGroup[0].scrollIntoView({ behavior: "smooth" });
            }
          }
          break;
        /*case "220":break;// cardIssuerId  cannot be null/empty */
        case "221":
        case "316":
          var input = $("#cardholderName");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          break;
        case "224":
        case "E302":
          var input = $("#securityCode");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
            }
          }
          break;
        default:
          console.warn("other error");
          break;
      }
    }
  }

  function cleanCardInfo() {
    document.getElementById("cardNumber").style.backgroundImage = "";
    document.getElementById("issuerInput").classList.add("hidden");
    document.getElementById("issuer").options.length = 0;
    document.getElementById("installments").options.length = 0;
  }
}
