import { stopLoading } from "./toggleSubmitLoading";
import { customAlert } from "../ui/alert";

export function alertPaymentStatus(statusDetail) {
  console.warn("alert payment", statusDetail);
  let msg = "Error en el pago. Por favor revisá los datos";
  switch (statusDetail) {
    case "cc_rejected_bad_filled_security_code":
      msg = "Código de seguridad incorrecto";
      break;
    case "security_code_id can't be null":
      msg = "El código de seguridad no puede estar vacío";
      break;
    default:
      msg = "Error en el pago. Por favor revisá los datos";
      break;
  }
  const alertText = `${msg}<br><small>${statusDetail}</small>`;
  if (typeof customAlert === "function") {
    customAlert(alertText);
  } else {
    alert(`${msg}\n${statusDetail}`);
  }
}

export function handleCardErrors(errors) {
  $(".form-group-w-error").removeClass("form-group-w-error");
  if (Array.isArray(errors)) {
    errors.forEach(function (error) {
      console.warn(error?.code, error?.message);
      const code = typeof error.code === "string" ? error.code : error.message;
      console.log(`CODE: "${code}"`);
      switch (code) {
        case "205":
        case "E301":
        case "parameter cardNumber can not be null/empty":
          var input = $("#cardNumber");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              stopLoading();
            }
          }
          break;
        case "208":
        case "325":
        case "parameter cardExpirationMonth can not be null/empty":
          var input = $("#cardExpirationMonth");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              stopLoading();
            }
          }
          break;
        case "209":
        case "326":
        case "E205":
        case "invalid expiration_year":
        case "parameter cardExpirationYear can not be null/empty":
          var input = $("#cardExpirationYear");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              stopLoading();
            }
          }
          break;
        /*case "212":break;// docType cannot be null/empty */
        /*case "322":break;// invalid docType */
        /*case "213":break;// cardholder.document.subtype cannot be null/empty */
        /*case "323":break;// invalid cardholder.document.subtype */
        case "214":
        case "324":
        case "parameter identificationNumber can not be null/empty":
          var input = $("#identificationNumber");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              inputGroup[0].scrollIntoView({ behavior: "smooth" });
              stopLoading();
            }
          }
          break;
        /*case "220":break;// cardIssuerId  cannot be null/empty */
        case "221":
        case "316":
        case "parameter cardholderName can not be null/empty":
          var input = $("#cardholderName");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              stopLoading();
            }
          }
          break;
        case "224":
        case "E302":
        case "parameter securityCode can not be null/empty":
          var input = $("#securityCode, #security_code");
          if (input.length > 0) {
            var inputGroup = input.closest(".form-group");
            if (inputGroup.length > 0) {
              inputGroup.addClass("form-group-w-error");
              stopLoading();
            }
          }
          break;
        default:
          console.warn("other error");
          stopLoading();
          break;
      }
    });
  }
}
