/**
 * A method to return the url hash value
 */
function getHash() {
  return window.location.hash;
}

/**
 * An event listener when the page loads
 */
window.addEventListener("load", () => {
  const hash = getHash();
  // If the url hash is not empty
  if (hash !== "") {
    // Get the element with the id of the hash
    const element = document.getElementById(hash.replace("#", ""));
    // If the element exists
    if (element) {
      // If the element has the class "collapse"
      if (element.classList.contains("collapse")) {
        // trigger collapse show
        $(element).collapse("show");
      }
      setTimeout(() => {
        // Scroll to the element
        element.scrollIntoView({ behavior: "smooth"});
      }, 1000);
    }
  }
});
