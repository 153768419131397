let lastParams = {
  pmid: null,
  installments: null,
  eventid: null,
};

let remounting = false;

let lastResponse = null;
/**
 *
 * @param {string|number} eventid
 * @param {number} pmid
 * @param {number} installments 0 = debit, 1 = credit in one payment, n = credit with installments
 * @returns
 */
export function updateService(eventid, pmid, installments) {
  /**
   * Code for wether is debit, credit or credit with installments
   * 0 = debit
   * 1 = credit in one payment
   * n = credit with installments
   */

  // if is debit, then the installments are 0
  let _installments = installments;
  const isDebitInput = document.getElementById("isDebit");
  if (isDebitInput) {
    if (parseInt(isDebitInput.value) < 0) {
      reject("Invalid card data installments");
      return;
    }
    _installments = parseInt(isDebitInput.value) * parseInt(installments);
  }
  console.log("installments to updateService", _installments, installments);
  const params = {
    pmid,
    installments: _installments,
    eventid,
  };
  console.log("cached lastResponse?", lastResponse, params, lastParams);
  if (
    params.pmid === lastParams.pmid &&
    params.installments === lastParams.installments &&
    params.eventid === lastParams.eventid
  ) {
    if (lastResponse) {
      return lastResponse;
    }
  }
  if (
    !window.savedcard &&
    !isNaN(remounting) &&
    params.installments !== remounting
  ) {
    remounting = false;
    if (lastResponse) {
      return lastResponse;
    }
  }
  lastParams = { ...params };

  const installmentsDom = document.getElementById("installments");
  let allServices = null;
  try {
    allServices = JSON.parse(installmentsDom.dataset.services);
  } catch (e) {
    console.error("error parsing services", e);
  }
  if (allServices === null || typeof allServices !== "object") {
    console.warn("services not found", services);
    return;
  }
  if (!allServices.hasOwnProperty(installments)) {
    console.warn(
      "installments not found in services",
      allServices,
      installments
    );
    return;
  }
  updateServiceDOM(1, allServices[params.installments], {
    installments: params.installments,
  });
}

export function updateServiceDOM(pmid, data, params) {
  const serviceDom = document.getElementById(`pmid-${pmid}-service`);
  const totalDom = document.getElementById(`pmid-${pmid}-amount`);
  serviceDom.innerHTML = data.service_value;
  totalDom.innerHTML = data.total_w_service;

  const formAmountDOM = document.getElementById("transactionAmount");
  if (formAmountDOM) {
    formAmountDOM.value = data.total_w_service;
  }
  const totalUYUDOM = document.getElementById("amount_uyu");
  if (totalUYUDOM) {
    totalUYUDOM.value = data.total_uyu;
  }
  if (window?.cardForm) {
    const cardFormData = window.cardForm.getCardFormData();
    console.log(
      "unmount window.cardForm",
      window.cardForm,
      data.total_uyu,
      cardFormData
    );
    console.log(
      "is different?",
      parseInt(cardFormData.amount),
      parseInt(data.total_uyu),
      parseInt(cardFormData.amount) !== parseInt(data.total_uyu)
    );
    if (parseInt(cardFormData.amount) !== parseInt(data.total_uyu)) {
      remounting = params.installments;
      window.cardForm.unmount();
      console.log("reload cardform");
      window.loadCardForm();
    }
  }
}
