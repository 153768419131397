export function customAlert(
  msg,
  btnPrimaryOptions = { msg: "Ok", callback: () => {} },
  btnSecondaryOptions = null,
  className = "",
  image = null
) {
  const modal = $("#buy-modal-alert");
  const dialog = modal.find(".modal-dialog");
  dialog.removeClass();
  dialog.addClass("modal-dialog");
  if (className !== "") {
    dialog.addClass(className);
  }
  const body = modal.find(".modal-body");

  body.html("");
  if (image !== null) {
    const tickyImg = document.createElement("img");
    tickyImg.src = image;
    tickyImg.classList.add("alert-image");
    const tickyMsg = document.createElement("p");
    tickyMsg.innerHTML = msg;
    tickyMsg.style.textAlign = "center";
    tickyMsg.style.margin = "0 auto";
    const container = document.createElement("div");
    container.style.textAlign = "center";
    container.style.width = "100%";
    container.appendChild(tickyImg);
    container.appendChild(tickyMsg);
    body.append(container);
  } else {
    body.html(msg);
  }

  const btnPrimary = modal.find(".btn-primary");
  btnPrimary.find(".text").text(btnPrimaryOptions.msg);
  btnPrimary.unbind("click");
  btnPrimary.bind("click", (event) => {
    modal.modal("hide");
    btnPrimaryOptions.callback(event);
  });

  const btnSecondary = modal.find(".btn-secondary");
  if (
    btnSecondaryOptions !== null &&
    typeof btnSecondaryOptions !== "undefined"
  ) {
    btnSecondary.show();
    btnSecondary.find(".text").text(btnSecondaryOptions.msg);
    btnSecondary.unbind("click");
    btnSecondary.bind("click", (event) => {
      modal.modal("hide");
      btnSecondaryOptions.callback(event);
    });
  } else {
    btnSecondary.hide();
  }

  modal.modal("show");
  modal.on("hide.bs.modal", () => body.html(""));
}
