import { apiCall } from "./api";

export default class User {
  static instance;

  status = "not-init";
  logged = undefined;
  data = null;

  constructor() {
    console.log("new User");
    this.fetchUser();
  }

  fetchUser() {
    this.status = "fetching";
    apiCall(
      "/actions/user",
      {},
      {
        success: (r) => {
          console.log("user response", r);
          if (r.hasOwnProperty("status")) {
            if (r.status === "success") {
              this.logged = true;
              this.data = r.data;
              console.log("user logged");
            } else if (r.status === "fail") {
              console.log("not logged");
              this.logged = false;
              this.data = null;
            }
          }
          this.status = "wait";
        },
      }
    );
  }

  static getInstance() {
    if (User.instance) {
      return User.instance;
    }
    User.instance = new User();
    return User.instance;
  }

  setUser(data) {
    this.data = data;
  }

  getUser() {
    if (this.data !== null) {
      return { ...this.data, logged: this.logged, status: this.status };
    }
    return { logged: false, status: this.status };
  }

  oAuthLogin(data) {
    console.log("register oAuth", data);
    const buying = $("#tickets-form").length > 0;
    console.log(buying ? "buying" : "logging in");
    apiCall("/actions/loginsocial", data, {
      success: (r) => {
        console.log("loginsocial response", r);
        if (r.hasOwnProperty("status") && r.status === "success") {
          if (!buying) {
            window.location.reload();
          } else {
            var nameSplit = r.data.name.split(" ");
            var username = r.data.name;
            var usersurname = "";
            if (nameSplit.length > 1) {
              username = nameSplit.shift();
              usersurname = nameSplit.join(" ");
            }
            $("#tickets-form [name=username]").val(username);
            $("#tickets-form [name=usersurname]").val(usersurname);
            $("#tickets-form [name=useremail]").val(r.data.email);
            $("#tickets-form [name=userphone]").val("");
            console.log("loginsocial while buying success");
            $("#tickets-form").submit();
          }
          return;
        }
        alert("Hubo un error al ingresar. Inténtalo de nuevo");
      },
    });
  }
}
