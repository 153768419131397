(function ($) {
  if (window.balancecheckout) {
    $(document).on("change", "[name=billingEnabled]", function (e) {
      const input = $(this);
      const value = input.prop("checked");
      console.log("change value", value);
      if (value) {
        $("#billing-switch-type").collapse("show");
        const checkedRUT = $("[name=billingType][value=rut]").prop("checked");
        if (checkedRUT) {
          $("#billing-rut").collapse("show");
          $("#billing-ci").collapse("hide");
        }
        const checkedCI = $("[name=billingType][value=ci]").prop("checked");
        if (checkedCI) {
          $("#billing-rut").collapse("hide");
          $("#billing-ci").collapse("show");
        }
      } else {
        $("#billing-switch-type").collapse("hide");
        $("#billing-rut").collapse("hide");
        $("#billing-ci").collapse("hide");
      }
    });
    $(document).on("change", "[name=billingType]", function (e) {
      const input = $(this);
      const value = input.val();
      if (value === "2") {
        $("#billing-rut").collapse("show");
        // $("#billing-ci").collapse("hide");
      } else {
        // $("#billing-rut").collapse("hide");
        $("#billing-ci").collapse("show");
      }
    });
  }
})(jQuery);
