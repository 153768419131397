import { apiCall } from "../api";

class AutoComplete {
  constructor(input) {
    this._input = input;
    this._suggestions = [];
    this._call = input.dataset["call"];
    this._params = JSON.parse(input.dataset["params"]);
    this._overlay = this.createSearchOverlay();
    this._resultsWrapper = this._overlay.querySelector(".search__results");
    this._loader = this._overlay.querySelector(".search__loader");
    this._previousValue = this._input.value;
    this._typingTimer;
    this._isSpinnerVisible = false;
    this._input.after(this._overlay);
    this._currentFocus = -1;
    this._errorContainer = this._input.parentNode.querySelector(".rrpp-error");
    this._valueInput = document.querySelector(
      `[name=${input.dataset["valuename"]}]`
    );
    this._selectedRRPP = null;
    if (this._valueInput.value !== "") {
      this._selectedRRPP = {
        name: this._input.value,
        rrppid: this._valueInput.value,
      };
    }

    this._input.addEventListener("keyup", (e) => {
      clearTimeout(this._typingTimer);
      if (this._input.value) {
        if (this._input.value != this._previousValue) {
          this._typingTimer = setTimeout(() => this.getResults(), 750);
        }
        this._previousValue = this._input.value;
      } else {
        this.hideResults();
      }
    });

    /*execute a function presses a key on the keyboard:*/
    this._input.addEventListener("keydown", (e) => {
      const activeElements = this._resultsWrapper.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /* If the arrow DOWN key is pressed, increase the currentFocus variable: */
        this._currentFocus++;
        /*and and make the current item more visible:*/
        this.addActive(activeElements);
      } else if (e.keyCode == 38) {
        //up
        /* If the arrow UP key is pressed, decrease the currentFocus variable: */
        this._currentFocus--;
        /*and and make the current item more visible:*/
        this.addActive(activeElements);
      } else if (e.keyCode == 13) {
        /* If the ENTER key is pressed, prevent the form from being submitted, */
        e.preventDefault();
        if (this._currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (activeElements) activeElements[this._currentFocus].click();
        }
      }
    });

    this._input.addEventListener("change", (e) => {
      this.validateInput();
    });

    this._input.addEventListener("blur", (e) => {
      setTimeout(() => {
        this.validateInput();
        this.closeList();
      }, 500);
    });

    console.log("Autocomplete is ready!", this);
  }

  validateInput() {
    if (
      this._selectedRRPP == null ||
      this._input.value !== this._selectedRRPP.name
    ) {
      this._valueInput.value = "";
      this._selectedRRPP = null;
      editRRPP("");
      if (this._input.value === "") {
        this.closeList();
        this._input.classList.remove("error");
        this._errorContainer.classList.add("hidden");
      } else {
        this._input.classList.add("error");
        this._errorContainer.classList.remove("hidden");
      }
    } else {
      this._input.classList.remove("error");
      this._errorContainer.classList.add("hidden");
    }
  }

  addActive = (activeElements) => {
    /*a function to classify an item as "active":*/
    if (!activeElements) return false;
    /*start by removing the "active" class on all items:*/
    this.removeActive(activeElements);
    if (this._currentFocus >= activeElements.length) this._currentFocus = 0;
    if (this._currentFocus < 0) this._currentFocus = activeElements.length - 1;
    /*add class "autocomplete-active":*/
    activeElements[this._currentFocus].classList.add("autocomplete-active");
    scrollParentToChild(
      this._resultsWrapper,
      activeElements[this._currentFocus]
    );
  };

  removeActive = (x) => {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  };

  getResults = () => {
    this._currentFocus = -1;

    if (this._input.value) {
      apiCall(
        "/actions/" + this._call,
        { ...this._params, search: this._input.value },
        {
          success: (response) => {
            if (typeof response?.data !== "undefined") {
              this._suggestions = response.data;
            } else {
              this._suggestions = [];
            }
            this.showResults();
          },
          error: (e1, e2, e3) => console.warn(e1, e2, e3),
        }
      );
    }
  };

  showResults = () => {
    if (!this._isSpinnerVisible) {
      this._loader.classList.add("spinner--visible");
      this._isSpinnerVisible = true;
    }
    this._resultsWrapper.innerHTML = "";
    this._suggestions.forEach((result) => {
      this._resultsWrapper.appendChild(this.createResultElement(result));
    });
    if (this._resultsWrapper.innerHTML == "") {
      this._resultsWrapper.innerHTML = `<p class="search__result">No encontramos resultados para <strong>${this._input.value}</strong></p>`;
    }
    this._loader.classList.remove("spinner--visible");
    this._isSpinnerVisible = false;
    this._overlay.classList.add("search__overlay--visible");
  };

  createResultElement(data) {
    const emailSplit = data.email.split("@");
    const email = `${emailSplit[0].substring(0, 2)}...${emailSplit[0].slice(
      -2
    )}@${emailSplit[1].substring(0, 2)}...${emailSplit[1].slice(-2)}`;
    let html = `<div>${data.name} <small>(${email})</small></div>`;
    const ele = HTMLtoDOMelement(html);
    const valuePropValue = data[this._input.dataset["valueprop"]];
    /* execute a function when someone clicks on the item value (DIV element): */
    ele.addEventListener("click", (e) => {
      /* insert the value for the autocomplete text field: */
      this._valueInput.value = valuePropValue;
      editRRPP(valuePropValue);
      triggerEvent(this._valueInput, "onchange");
      this._input.value = data.name;
      this._selectedRRPP = data;
      this._input.classList.remove("error");
      /* close the list of autocompleted values, (or any other open lists of autocompleted values: */
      this.closeList();
      this.validateInput();
    });
    return ele;
  }

  createSearchOverlay = () => {
    return HTMLtoDOMelement(`<div class="search__overlay">
        <div class="search__results"></div>
        <div class="search__loader"></div>
      </div>`);
  };

  closeList = () => {
    this._resultsWrapper.innerHTML = "";
  };
}

export default AutoComplete;

function scrollParentToChild(parent, child) {
  // Where is the parent on page
  var parentRect = parent.getBoundingClientRect();
  // What can you see?
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };

  // Where is the child
  var childRect = child.getBoundingClientRect();
  // Is the child viewable?
  var isViewable =
    childRect.top >= parentRect.top &&
    childRect.bottom <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // Should we scroll using top or bottom? Find the smaller ABS adjustment
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      // we're near the top of the list
      parent.scrollTop += scrollTop;
    } else {
      // we're near the bottom of the list
      parent.scrollTop += scrollBot;
    }
  }
}

function HTMLtoDOMelement(html) {
  const template = document.createElement("div");
  template.innerHTML = html;
  console.log("template", template);
  return template.firstElementChild;
}

function triggerEvent(ele, type) {
  if ("createEvent" in document) {
    // modern browsers, IE9+
    var e = document.createEvent("HTMLEvents");
    e.initEvent(type, false, true);
    ele.dispatchEvent(e);
  } else {
    // IE 8
    var e = document.createEventObject();
    e.eventType = type;
    ele.fireEvent("on" + e.eventType, e);
  }
}

function editRRPP(rrpp) {
  apiCall(
    "/actions/editCartRRPP",
    {
      rrpp: rrpp,
    },
    {
      success: (response) => {
        console.log("response", response);
      },
      error: (jqXHR, textStatus, asdf) => {
        console.log("editTicket error", jqXHR, textStatus, asdf);
      },
    }
  );
}
