validateBuyersSection();

$(document).on("change", "#user-data-section input", function (event) {
  validateBuyersSection();
});

function validateBuyersSection() {
  const container = $("#user-data-section");
  let allValid = true;
  container.find("input, textarea, select").each(function (index, input) {
    console.log(index, "input", input, $(input).val());
    const formGroup = $(input).closest(".form-group");
    if ($(input).val() === "") {
      formGroup.addClass("form-group-w-error");
      allValid = false;
    } else {
      formGroup.removeClass("form-group-w-error");
    }
  });
  const btn = $("#validate-user-data-btn");
  if (allValid) {
    btn.hide();
    $("#payment-section").collapse("show");
  } else {
    btn.show();
    $("#payment-section").collapse("hide");
  }
}
