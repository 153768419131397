import User from "../user";
import { FACEBOOK_APP_ID } from "../constants";

const user = User.getInstance();

window.fbAsyncInit = function () {
  console.log("window.fbAsyncInit");
  FB.init({
    appId: FACEBOOK_APP_ID,
    cookie: true,
    xfbml: true,
    version: "v13.0",
  });

  FB.AppEvents.logPageView();

  FB.getLoginStatus(function (response) {
    statusChangeCallback(response);
  });
};

window.fbLogin = function () {
  console.log("facebook login fbLogin");
  FB.login(statusLoginCallback, { scope: "public_profile,email" });
};

function statusChangeCallback(response) {
  console.log("statusChangeCallback");
  /**
   * connected: la persona inició sesión en Facebook y en tu aplicación.
   * not_authorized: la persona inició sesión en Facebook, pero no en tu aplicación.
   * unknown: la persona no inició sesión en Facebook y no sabes si lo hizo en tu aplicación o si se llamó antes al método FB.logout(), por lo que no puede conectarse a Facebook.
   * authResponse: se incluye si el estado es connected, y consta de los siguientes elementos:
   * accessToken: contiene un token de acceso para la persona que usa la aplicación.
   * expiresIn: indica la hora UNIX en que el token caduca y se debe renovar.
   * signedRequest: un parámetro firmado que contiene información sobre la persona que usa la aplicación.
   * userID: es el identificador de la persona que usa la aplicación.
   */
  console.log("facebook response", response);

  console.log(user.getUser());
}

function statusLoginCallback(loginResponse) {
  console.log("statusLoginCallback");
  /**
   * connected: la persona inició sesión en Facebook y en tu aplicación.
   * not_authorized: la persona inició sesión en Facebook, pero no en tu aplicación.
   * unknown: la persona no inició sesión en Facebook y no sabes si lo hizo en tu aplicación o si se llamó antes al método FB.logout(), por lo que no puede conectarse a Facebook.
   * authResponse: se incluye si el estado es connected, y consta de los siguientes elementos:
   * accessToken: contiene un token de acceso para la persona que usa la aplicación.
   * expiresIn: indica la hora UNIX en que el token caduca y se debe renovar.
   * signedRequest: un parámetro firmado que contiene información sobre la persona que usa la aplicación.
   * userID: es el identificador de la persona que usa la aplicación.
   */
  console.log("facebook login response", loginResponse);

  if (loginResponse.status === "connected") {
    const params = {
      oauth: loginResponse.authResponse.accessToken,
    };
    // Logged into your webpage and Facebook.
    FB.api(
      "/me",
      "GET",
      { fields: "id,name,birthday,email,picture" },
      function (meResponse) {
        console.log("Successful login for: " + meResponse.name);
        console.log(meResponse);
        params.name = meResponse.name;
        params.email = meResponse.email;
        params.avatar = meResponse.picture.data.url;
        user.oAuthLogin(params);
      }
    );

    // FB.api("/me", function (meResponse) {
    //   console.log("Successful login for: " + meResponse.name);
    //   console.log(meResponse);
    // });
    //
  } else {
    // The person is not logged into your webpage or we are unable to tell.
    alert("Hubo un error conectando con Facebook");
  }

  console.log(user.getUser());
}

const checkLoginState = () => {
  console.log("checkLoginState");
  FB.getLoginStatus(function (response) {
    statusChangeCallback(response);
  });
};

(function (d, s, id) {
  window.checkLoginState = checkLoginState;
  console.log("init facebook");

  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");
