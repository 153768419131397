import { getCookie, setCookie } from "./includes/utlis/cookies";

$(document).on("submit", "#tickets-form", function (event) {
  var form = $(event.currentTarget);
  if (typeof fbq === "function") {
    var content_ids = [];
    var content_name = form.find("input[name=event]").val();
    var content_type = "product";
    var contents = [];
    var price = 0;
    form.find("select.quantity option:selected").each(function (i, option) {
      var ticketDOM = $(option).closest(".ticket");
      var ticketData = $(ticketDOM).data("ticket");
      var ticketPrice = parseFloat(ticketData.price);
      var etid = ticketData.etid;
      var value = $(option).val();
      var arr = value.split("|");
      var qty = parseInt(arr[0]);
      price += ticketPrice * qty;
      if (qty > 0) {
        var content_object = { id: etid, quantity: qty };
        for (var index = 0; index < qty; index++) {
          content_ids.push(etid);
          contents.push(content_object);
        }
      }
    });
    var fbqParams = {
      content_ids: content_ids,
      content_name: content_name,
      content_type: content_type,
      contents: contents,
      currency: "UYU",
      value: price,
    };
    console.log(content_ids);
    console.log("track AddToCart", fbqParams);
    fbq("track", "AddToCart", fbqParams);
  }
});

$(document).on("click", "#payment-section", function (event) {
  var content_category = "Event";
  var content_ids = [];
  var contents = [];
  var currency = "UYU";
  var num_items = 0;
  var price = parseFloat($("#amount").text());

  var cartjson = JSON.parse($("[name=cartjson]").val());
  console.log("cartjson", cartjson);

  var tickethash = cartjson.tickethash;
  var cookie = getCookie("initcart");
  if (cookie == null || cookie !== tickethash) {
    console.log("INITIATE_CHECKOUT");
    var date = new Date();
    date.setDate(date.getDate() + 1);
    setCookie("initcart", tickethash, date);

    cartjson.tickets.forEach(function (ticket) {
      var content_object = { id: ticket.etid, quantity: ticket.qty };
      for (var index = 0; index < ticket.qty; index++) {
        content_ids.push(ticket.etid);
        contents.push(content_object);
        num_items++;
      }
    });

    var fbqParams = {
      content_category: content_category,
      content_ids: content_ids,
      contents: contents,
      currency: currency,
      num_items: num_items,
      value: price,
    };
    console.log("track", "InitiateCheckout", fbqParams);
    fbq("track", "InitiateCheckout", fbqParams);
  }
});

$(document).ready(function () {
  if (typeof window.successtickets !== "undefined") {
    if (window.successtickets.contents.length > 0) {
      console.log("fbq success", window.successtickets);
      fbq("track", "Purchase", window.successtickets);
    }
  }
});
