import "./vendor/animate.min.scss";
import "./styles/style.scss";

import User from "./includes/user";

import "./includes/ui";

import "./includes/payments_v2";
import "./includes/payment_savedcard";
import "./includes/payment_newcard";
import "./includes/addcardwebview";
import "./includes/cvvwebview";
import "./includes/tickets";
import "./includes/login";
import "./includes/userPaymentData";
import "./includes/help";
import "./includes/flyer";
import "./includes/removecard";
import "./facebooktracking";
import { initInstagramLogin } from "./includes/utlis/instagram";
import "./includes/products";
import QuickSearch from "./includes/quicksearch";
import "./includes/downloadAppOverlay";
import AutoComplete from "./includes/ui/autocomplete";
import "./includes/billing";
import "./includes/eventmap";
import "./includes/devolucion";

new WOW({
  boxClass: "wow",
  animateClass: "animate__animated",
}).init();

console.log("init bundle");

const user = User.getInstance();

console.log(user.getUser());

initInstagramLogin();

new QuickSearch(".quicksearch");

const autocompleteInputs = document.getElementsByClassName("autocomplete");
console.log("autocompleteInputs", autocompleteInputs);
Array.from(autocompleteInputs).forEach((input) => {
  new AutoComplete(input);
});
